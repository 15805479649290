@tailwind base;
@tailwind components;
@tailwind utilities;

.property-h2 {
  @apply mt-8 border-b-4 border-slate-500 pb-1 text-2xl font-semibold;
}

.property-h3 {
  @apply mb-1 mt-2 border-b-2 border-slate-500 pb-1 text-xl font-semibold;
}

.property-p {
  @apply flex space-x-1 text-lg;
}
