@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content-x {
  @apply mx-auto w-full max-w-screen-2xl px-2 min-[380px]:px-4 xl:px-8;
}

.content-y {
  @apply pb-24 pt-8;
}

.click-text {
  @apply cursor-pointer text-blue-800 hover:text-blue-500;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 640px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 12px;
  }
}
