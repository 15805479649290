.button-common-styles {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.scrollbar {
  scrollbar-width: thin;
}
.scrollbar ::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-thumb:active,
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.cliparea-module_cliparea__GdPrN {
  display: none !important;
  background: transparent !important;
  border: none !important;
  bottom: 0;
  color: transparent !important;
  display: block;
  font-size: 1px;
  height: 0 !important;
  left: 0;
  outline: none;
  overflow: hidden;
  padding: 0 !important;
  position: absolute;
  resize: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  width: 0 !important;
  z-index: -1;
}
.ActionButton-module_button-common-styles__y-hng {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.ActionButton-module_scrollbar__lD3MH {
  scrollbar-width: thin;
}
.ActionButton-module_scrollbar__lD3MH ::-webkit-scrollbar-track {
  background-color: transparent;
}
.ActionButton-module_scrollbar__lD3MH::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.ActionButton-module_scrollbar__lD3MH::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.ActionButton-module_scrollbar__lD3MH::-webkit-scrollbar-thumb:active,
.ActionButton-module_scrollbar__lD3MH::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.ActionButton-module_button__nfoWQ {
  background-color: #fff;
  background-image: none;
  background: inherit;
  border: 0;
  border-radius: 2px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  display: block;
  height: 32px;
  margin: 4px;
  padding: 0;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 32px;
}
.ActionButton-module_button__nfoWQ:active {
  background-image: none;
  outline: 0;
}
.ActionButton-module_button__nfoWQ[disabled],
fieldset[disabled] .ActionButton-module_button__nfoWQ {
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
}
.ActionButton-module_button__nfoWQ > input[type='file'] {
  left: -9999px;
  position: absolute;
}
.ActionButton-module_button__nfoWQ + button,
.ActionButton-module_button__nfoWQ + input[type='reset'],
.ActionButton-module_button__nfoWQ + input[type='submit'] {
  margin-left: 0.3em;
}
.ActionButton-module_button__nfoWQ:after,
.ActionButton-module_button__nfoWQ:before {
  display: inline-block;
  vertical-align: middle;
}
.ActionButton-module_button__nfoWQ:after {
  left: 0;
  position: absolute;
}
.ActionButton-module_button__nfoWQ:hover {
  background-image: none;
}
.ActionButton-module_button__nfoWQ:hover path {
  fill: #006775;
}
.ActionButton-module_button__nfoWQ:active,
.ActionButton-module_button__nfoWQ[disabled] {
  box-shadow: none;
  transform: scale(none);
  transform: scale(1);
  transition: transform, 0.2s, ease-out;
}
.ActionButton-module_button__nfoWQ > svg {
  fill: currentColor;
  font-size: 26px;
  height: 26px;
  left: 0.1em;
  position: absolute;
  top: 0.1em;
  width: 26px;
}
.ActionButton-module_button__nfoWQ.ActionButton-module_selected__kPCxA,
.ActionButton-module_button__nfoWQ:active {
  background: #006775;
}
.ActionButton-module_button__nfoWQ.ActionButton-module_selected__kPCxA path,
.ActionButton-module_button__nfoWQ:active path {
  fill: #fff;
}
.ActionButton-module_button__nfoWQ.ActionButton-module_selected__kPCxA span,
.ActionButton-module_button__nfoWQ:active span {
  color: #fff;
}
.ActionButton-module_button__nfoWQ.ActionButton-module_selected__kPCxA:hover,
.ActionButton-module_button__nfoWQ:active:hover {
  background-color: #188794;
}
.DefaultMultiTool-module_default__zNTMe button {
  display: initial;
}
.GroupedMultiTool-module_group__BJQAU {
  display: flex;
  flex-direction: column;
}
.GroupedMultiTool-module_group__BJQAU:not(:first-of-type) {
  border-top: 1px solid #cad3dd;
  margin-top: 4px;
  padding-top: 5px;
}
.ToolbarMultiToolItem-module_button-common-styles__Fuxbo {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.ToolbarMultiToolItem-module_scrollbar__XTmfz {
  scrollbar-width: thin;
}
.ToolbarMultiToolItem-module_scrollbar__XTmfz ::-webkit-scrollbar-track {
  background-color: transparent;
}
.ToolbarMultiToolItem-module_scrollbar__XTmfz::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.ToolbarMultiToolItem-module_scrollbar__XTmfz::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.ToolbarMultiToolItem-module_scrollbar__XTmfz::-webkit-scrollbar-thumb:active,
.ToolbarMultiToolItem-module_scrollbar__XTmfz::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.ToolbarMultiToolItem-module_icon__-HQal {
  fill: #333;
  bottom: 0;
  display: block;
  height: 7px;
  position: absolute;
  right: 0;
  width: 7px;
}
.ToolbarMultiToolItem-module_iconSelected__hlO0J {
  fill: #fff;
}
.ToolbarMultiToolItem-module_portal__2XJgz {
  background: #fff;
  border: 1px solid #cad3dd;
  border-radius: 5px;
  box-shadow: 3px 4px 4px rgba(103, 104, 132, 0.25);
  position: absolute;
  white-space: nowrap;
  word-break: keep-all;
  z-index: 20;
}
.ToolbarMultiToolItem-module_portal__2XJgz > button {
  height: 28px;
  width: 28px;
}
.ToolbarMultiToolItem-module_portal__2XJgz > button > svg {
  left: 1px;
  top: 1px;
}
.ToolbarMultiToolItem-module_portal__2XJgz kbd {
  color: #cad3dd;
  display: none;
  font: 0.8em monospace;
}
.ToolbarMultiToolItem-module_portal-vertical__A0DVe {
  display: flex;
  flex-direction: column;
}
.BottomToolbar-module_button-common-styles__PbtE9 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.BottomToolbar-module_scrollbar__i1f8P {
  scrollbar-width: thin;
}
.BottomToolbar-module_scrollbar__i1f8P ::-webkit-scrollbar-track {
  background-color: transparent;
}
.BottomToolbar-module_scrollbar__i1f8P::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.BottomToolbar-module_scrollbar__i1f8P::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.BottomToolbar-module_scrollbar__i1f8P::-webkit-scrollbar-thumb:active,
.BottomToolbar-module_scrollbar__i1f8P::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.BottomToolbar-module_group__b-pGt {
  display: flex;
  flex-wrap: wrap;
}
.BottomToolbar-module_group__b-pGt:nth-child(2) {
  display: none;
}
.BottomToolbar-module_root__kjkSm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 40px;
}
.BottomToolbar-module_root__kjkSm div {
  position: relative;
}
.BottomToolbar-module_root__kjkSm kbd {
  color: #cad3dd;
  display: none;
  font: 0.8em monospace;
}
.BottomToolbar-module_root__kjkSm .BottomToolbar-module_group__b-pGt {
  flex-direction: row;
}
.BottomToolbar-module_root__kjkSm button {
  height: 28px;
  margin: 2px;
  width: 28px;
}
.BottomToolbar-module_root__kjkSm button > svg {
  left: 1px;
  top: 1px;
}
.ArrowScroll-module_scroll__h9kCx {
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: 32px;
  width: 32px;
}
.ArrowScroll-module_button__ygMgL {
  background: transparent;
  border: none;
  font-size: 15px !important;
  height: 50%;
  width: 100%;
}
.ArrowScroll-module_down__yDzZX {
  bottom: 0;
  position: absolute;
}
.ArrowScroll-module_up__Q1ubd {
  position: absolute;
  top: 0;
}
.LeftToolbar-module_button-common-styles__WgnON {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.LeftToolbar-module_scrollbar__OBwid {
  scrollbar-width: thin;
}
.LeftToolbar-module_scrollbar__OBwid ::-webkit-scrollbar-track {
  background-color: transparent;
}
.LeftToolbar-module_scrollbar__OBwid::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.LeftToolbar-module_scrollbar__OBwid::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.LeftToolbar-module_scrollbar__OBwid::-webkit-scrollbar-thumb:active,
.LeftToolbar-module_scrollbar__OBwid::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.LeftToolbar-module_group__0s41t {
  display: flex;
}
.LeftToolbar-module_root__yhhZm {
  -ms-overflow-style: none;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  margin-top: 8px;
  overflow: hidden;
  scrollbar-width: thin;
  width: 32px;
}
.LeftToolbar-module_root__yhhZm div {
  position: relative;
}
.LeftToolbar-module_root__yhhZm kbd {
  color: #cad3dd;
  display: none;
  font: 0.8em monospace;
}
.LeftToolbar-module_root__yhhZm .LeftToolbar-module_group__0s41t {
  flex-direction: column;
}
.LeftToolbar-module_root__yhhZm .LeftToolbar-module_group__0s41t:not(:last-of-type) {
  border-bottom: 1px solid #eff2f5;
  margin-bottom: 4px;
  padding-bottom: 5px;
}
.LeftToolbar-module_root__yhhZm ::-webkit-scrollbar-track {
  background-color: transparent;
}
.LeftToolbar-module_root__yhhZm::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.LeftToolbar-module_root__yhhZm::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.LeftToolbar-module_root__yhhZm::-webkit-scrollbar-thumb:active,
.LeftToolbar-module_root__yhhZm::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.LeftToolbar-module_root__yhhZm .LeftToolbar-module_group__0s41t:not(:last-of-type) {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.LeftToolbar-module_buttons__lnIjn {
  overflow: hidden;
  scroll-behavior: smooth;
}
.LeftToolbar-module_buttons__lnIjn div:nth-child(3) {
  display: none;
}
.LeftToolbar-module_buttons__lnIjn div:nth-child(n + 6):nth-child(-n + 10) {
  display: none;
}
.LeftToolbar-module_borderOff__om425 {
  border-bottom: none !important;
}
.LeftToolbar-module_groupItem__OqQu0 {
  margin: 2px 0;
}
.LeftToolbar-module_groupItem__OqQu0 svg {
  bottom: 2px;
  right: 2px;
}
.LeftToolbar-module_groupItem__OqQu0 button {
  height: 28px;
  margin: 2px;
  width: 28px;
}
.LeftToolbar-module_groupItem__OqQu0 button > svg {
  left: 1px;
  top: 1px;
}
.Atom-module_button-common-styles__j3EUJ {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Atom-module_scrollbar__iO-Ni {
  scrollbar-width: thin;
}
.Atom-module_scrollbar__iO-Ni ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Atom-module_scrollbar__iO-Ni::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Atom-module_scrollbar__iO-Ni::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Atom-module_scrollbar__iO-Ni::-webkit-scrollbar-thumb:active,
.Atom-module_scrollbar__iO-Ni::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Atom-module_atom__g2RUu {
  background-color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #cad3dd;
  border-radius: 2px;
  border-radius: 0;
  color: #333;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  margin: 2px 4px;
  padding: 0;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 32px;
}
.Atom-module_atom__g2RUu:active {
  background-image: none;
  outline: 0;
}
.Atom-module_atom__g2RUu[disabled],
fieldset[disabled] .Atom-module_atom__g2RUu {
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
}
.Atom-module_atom__g2RUu > input[type='file'] {
  left: -9999px;
  position: absolute;
}
.Atom-module_atom__g2RUu + button,
.Atom-module_atom__g2RUu + input[type='reset'],
.Atom-module_atom__g2RUu + input[type='submit'] {
  margin-left: 0.3em;
}
.Atom-module_atom__g2RUu[disabled] {
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.RightToolbar-module_button-common-styles__fNlqY {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.RightToolbar-module_scrollbar__2Q0UU {
  scrollbar-width: thin;
}
.RightToolbar-module_scrollbar__2Q0UU ::-webkit-scrollbar-track {
  background-color: transparent;
}
.RightToolbar-module_scrollbar__2Q0UU::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.RightToolbar-module_scrollbar__2Q0UU::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.RightToolbar-module_scrollbar__2Q0UU::-webkit-scrollbar-thumb:active,
.RightToolbar-module_scrollbar__2Q0UU::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.RightToolbar-module_group__3mhUx:nth-child(1) {
  display: flex;
}
.RightToolbar-module_group__3mhUx:nth-child(2) {
  display: none;
}
.RightToolbar-module_root__1D5hp {
  -ms-overflow-style: none;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  margin-top: 8px;
  overflow: hidden;
  scrollbar-width: thin;
  width: 32px;
}
.RightToolbar-module_root__1D5hp div {
  position: relative;
}
.RightToolbar-module_root__1D5hp kbd {
  color: #cad3dd;
  display: none;
  font: 0.8em monospace;
}
.RightToolbar-module_root__1D5hp .RightToolbar-module_group__3mhUx {
  flex-direction: column;
}
.RightToolbar-module_root__1D5hp .RightToolbar-module_group__3mhUx:not(:last-of-type) {
  border-bottom: 1px solid #eff2f5;
  margin-bottom: 4px;
  padding-bottom: 5px;
}
.RightToolbar-module_root__1D5hp ::-webkit-scrollbar-track {
  background-color: transparent;
}
.RightToolbar-module_root__1D5hp::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.RightToolbar-module_root__1D5hp::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.RightToolbar-module_root__1D5hp::-webkit-scrollbar-thumb:active,
.RightToolbar-module_root__1D5hp::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.RightToolbar-module_root__1D5hp .RightToolbar-module_group__3mhUx:not(:last-of-type) {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.RightToolbar-module_root__1D5hp
  .RightToolbar-module_group__3mhUx:not(:last-of-type).RightToolbar-module_atomsList__ar08- {
  margin-bottom: 8px;
  margin-top: 2px;
}
.RightToolbar-module_buttons__YxRHI {
  overflow: hidden;
  scroll-behavior: smooth;
  width: 100%;
}
.RightToolbar-module_buttons__YxRHI button {
  font-size: 14px;
  font-weight: 600;
  height: 28px;
  margin: 2px;
  width: 28px;
}
.RightToolbar-module_buttons__YxRHI button svg {
  bottom: 2px;
  left: 1px;
  right: 2px;
  top: 1px;
}
.Modal-module_button-common-styles__ko9E5 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Modal-module_scrollbar__yPIJc {
  scrollbar-width: thin;
}
.Modal-module_scrollbar__yPIJc ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Modal-module_scrollbar__yPIJc::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Modal-module_scrollbar__yPIJc::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Modal-module_scrollbar__yPIJc::-webkit-scrollbar-thumb:active,
.Modal-module_scrollbar__yPIJc::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Modal-module_modalOverlay__AzVeg {
  align-items: center;
  background-color: rgba(94, 94, 94, 0.2);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cpath d='M0 10 10 0zm12-2-4 4zM-2 2l4-4z' stroke='%23555' stroke-width='2' stroke-opacity='.02'/%3E%3C/svg%3E");
  background: none\9;
  bottom: 0;
  display: flex;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#335e5e5e",endColorstr="#335e5e5e");
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 40;
}
.Modal-module_modalOverlay__AzVeg:not([ie9]) {
  filter: progid:dximagetransform.microsoft.gradient(enabled="false");
}
.Modal-module_modalOverlay__AzVeg.Modal-module_animate__pnPNj {
  transition: background-color 0.3s;
}
.Modal-module_modalOverlay__AzVeg.Modal-module_hide__0CTey {
  background-color: rgba(94, 94, 94, 0.01);
}
.Modal-module_modalOverlay__AzVeg:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.Modal-module_modalOverlay__AzVeg .Modal-module_smallScreen__nzt2L {
  max-height: 90%;
  max-width: 90%;
}
.Select-module_button-common-styles__cgaVz {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Select-module_scrollbar__cA89D {
  scrollbar-width: thin;
}
.Select-module_scrollbar__cA89D ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Select-module_scrollbar__cA89D::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Select-module_scrollbar__cA89D::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Select-module_scrollbar__cA89D::-webkit-scrollbar-thumb:active,
.Select-module_scrollbar__cA89D::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Select-module_dropdownList__rdcrH .MuiPaper-root {
  border-radius: 0 0 4px 4px;
  box-shadow: 0 6px 10px rgba(103, 104, 132, 0.15);
  color: #333;
}
.Select-module_dropdownList__rdcrH .MuiMenuItem-root {
  color: #333;
  cursor: default;
  font-family:
    Inter,
    FreeSans,
    Arimo,
    Droid Sans,
    Helvetica,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
  min-height: 28px;
  padding: 0 8px;
}
.Select-module_dropdownList__rdcrH .MuiMenuItem-root:hover {
  background-color: rgba(243, 245, 247, 0.6);
  border-left: 2px solid #167782;
  padding-left: 6px;
}
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list {
  background-color: #fff;
  box-shadow: none;
  display: inline-block;
  max-height: 200px;
  overflow: auto;
  padding: 0;
  scrollbar-width: thin;
  width: 100%;
}
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list::-webkit-scrollbar-thumb:active,
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
}
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
.Select-module_dropdownList__rdcrH .MuiList-root.MuiMenu-list .Mui-selected {
  background-color: #fff;
}
.Select-module_dropdownList__rdcrH .Select-module_listDivider__4vJyh {
  border: 0.5px solid #e1e5ea;
  margin: 4px 8px;
}
.Select-module_selectContainer__yXT-t {
  width: 100%;
}
.Select-module_selectContainer__yXT-t .MuiOutlinedInput-root .MuiSelect-select {
  background-color: #fff;
  border: 1px solid #cad3dd;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  font-family:
    Inter,
    FreeSans,
    Arimo,
    Droid Sans,
    Helvetica,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
  height: 24px;
  line-height: 22px;
  min-height: 24px;
  padding: 0 8px;
  padding-right: 24px !important;
  width: 100%;
}
.Select-module_selectContainer__yXT-t .MuiOutlinedInput-root .MuiSelect-select .MuiSelect-nativeInput {
  border: none;
  padding: 0;
}
.Select-module_selectContainer__yXT-t .MuiOutlinedInput-root .MuiSelect-select:hover {
  border: 1px solid #43b5c0;
}
.Select-module_selectContainer__yXT-t .Mui-focused .MuiSelect-select {
  border-color: #167782;
}
.Select-module_selectContainer__yXT-t .MuiOutlinedInput-notchedOutline {
  border: none;
}
.Select-module_selectContainer__yXT-t .MuiSelect-icon {
  height: 16px;
  width: 16px;
}
.Dialog-module_button-common-styles__HcaFV {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Dialog-module_scrollbar__9Iy27 {
  scrollbar-width: thin;
}
.Dialog-module_scrollbar__9Iy27 ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Dialog-module_scrollbar__9Iy27::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Dialog-module_scrollbar__9Iy27::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Dialog-module_scrollbar__9Iy27::-webkit-scrollbar-thumb:active,
.Dialog-module_scrollbar__9Iy27::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Dialog-module_dialog__IafFQ {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 8px;
  color: #585858;
  display: flex;
  flex-direction: column;
  font-family:
    Inter,
    FreeSans,
    Arimo,
    Droid Sans,
    Helvetica,
    Helvetica Neue,
    Arial,
    sans-serif;
  opacity: 1;
  outline: 0;
  position: relative;
  text-align: left;
  transform: scale(1);
  transition:
    transform 0.3s,
    opacity 0.3s;
  vertical-align: middle;
}
.Dialog-module_dialog__IafFQ.Dialog-module_hide__6R16B {
  opacity: 0.2;
  transform: scale(0.5);
}
.Dialog-module_header__T7-24 {
  align-items: center;
  border-radius: 6px 6px 0 0;
  box-sizing: content-box;
  color: #333;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  padding: 12px;
}
.Dialog-module_header__T7-24 .Dialog-module_btnContainer__TOLPx {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.Dialog-module_header__T7-24 .Dialog-module_buttonTop__ZhwZQ {
  align-self: center;
  background-color: transparent;
  border: none;
  height: 16px;
  margin-left: auto;
  padding: 0;
  width: 16px;
}
.Dialog-module_header__T7-24 .Dialog-module_buttonTop__ZhwZQ .Dialog-module_closeButton__Ch6UT {
  height: 100%;
  width: 100%;
}
.Dialog-module_footer__NTq5o {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 16px 12px 12px;
}
.Dialog-module_footer__NTq5o .Dialog-module_ok__T4j7Z {
  align-items: center;
  background-color: #167782;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Dialog-module_footer__NTq5o .Dialog-module_ok__T4j7Z:active,
.Dialog-module_footer__NTq5o .Dialog-module_ok__T4j7Z:hover {
  background-color: #4fb3bf;
}
.Dialog-module_footer__NTq5o .Dialog-module_ok__T4j7Z:disabled {
  background-color: #e1e5ea;
  color: #333;
}
.Dialog-module_footer__NTq5o .Dialog-module_cancel__RcR-D {
  align-items: center;
  background-color: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Dialog-module_footer__NTq5o .Dialog-module_cancel__RcR-D:active,
.Dialog-module_footer__NTq5o .Dialog-module_cancel__RcR-D:hover {
  border: 1px solid #333;
  color: #333;
}
.Dialog-module_footer__NTq5o .Dialog-module_cancel__RcR-D:disabled {
  border: 1px solid rgba(88, 88, 88, 0.7);
  color: rgba(88, 88, 88, 0.7);
}
.Dialog-module_withDivider__8LsbA {
  border-bottom: 1px solid #e1e5ea;
  height: 36px;
  margin: 0;
  padding: 0 12px;
}
.Dialog-module_body__YgzDR {
  font-size: 12px;
  height: 100%;
  line-height: 14px;
  overflow: auto;
  scrollbar-width: thin;
}
.Dialog-module_body__YgzDR ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Dialog-module_body__YgzDR::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Dialog-module_body__YgzDR::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Dialog-module_body__YgzDR::-webkit-scrollbar-thumb:active,
.Dialog-module_body__YgzDR::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Dialog-module_withMargin__SDH61 {
  margin: 0 12px;
}
.LoadingCircles-module_container__MPCs0 {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 12px;
  justify-content: space-between;
  margin-top: 10px;
  width: 40px;
}
.LoadingCircles-module_container__MPCs0 span {
  border: 2px solid #005662;
  border-radius: 100%;
  box-sizing: border-box;
  display: inline-block;
  height: 8px;
  width: 8px;
}
.LoadingCircles-module_container__MPCs0 span:first-child {
  animation: LoadingCircles-module_bounce__gCepS 1s ease-in-out infinite;
}
.LoadingCircles-module_container__MPCs0 span:nth-child(2) {
  animation: LoadingCircles-module_bounce__gCepS 1s ease-in-out 0.33s infinite;
}
.LoadingCircles-module_container__MPCs0 span:nth-child(3) {
  animation: LoadingCircles-module_bounce__gCepS 1s ease-in-out 0.66s infinite;
}
@keyframes LoadingCircles-module_bounce__gCepS {
  0%,
  75%,
  to {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-100%);
  }
}
.StructEditor-module_button-common-styles__3P7PO {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.StructEditor-module_scrollbar__SUs-o {
  scrollbar-width: thin;
}
.StructEditor-module_scrollbar__SUs-o ::-webkit-scrollbar-track {
  background-color: transparent;
}
.StructEditor-module_scrollbar__SUs-o::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.StructEditor-module_scrollbar__SUs-o::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.StructEditor-module_scrollbar__SUs-o::-webkit-scrollbar-thumb:active,
.StructEditor-module_scrollbar__SUs-o::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.StructEditor-module_spinnerOverlay__5H1IM {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
}
.StructEditor-module_canvas__LLnzh {
  overflow: hidden;
  position: relative;
  scrollbar-width: thin;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.StructEditor-module_canvas__LLnzh .StructEditor-module_measureLog__B2wuy {
  background-color: #fff;
  border: 1px solid #cad3dd;
  border-radius: 0 5px;
  height: 1.2em;
  min-width: 8em;
  opacity: 0;
  padding: 0.3em;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity 0.5s;
}
.StructEditor-module_canvas__LLnzh .StructEditor-module_measureLog__B2wuy.StructEditor-module_visible__3eFNB {
  opacity: 1;
}
.StructEditor-module_canvas__LLnzh ::-webkit-scrollbar-track {
  background-color: transparent;
}
.StructEditor-module_canvas__LLnzh::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.StructEditor-module_canvas__LLnzh::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.StructEditor-module_canvas__LLnzh::-webkit-scrollbar-thumb:active,
.StructEditor-module_canvas__LLnzh::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.StructEditor-module_intermediateCanvas__fR3ws {
  height: 100%;
  overflow: auto;
  position: relative;
  scrollbar-width: thin;
  width: 100%;
}
.StructEditor-module_intermediateCanvas__fR3ws.StructEditor-module_enableCursor__Qla0A {
  cursor: none;
}
.StructEditor-module_intermediateCanvas__fR3ws ::-webkit-scrollbar-track {
  background-color: transparent;
}
.StructEditor-module_intermediateCanvas__fR3ws::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.StructEditor-module_intermediateCanvas__fR3ws::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.StructEditor-module_intermediateCanvas__fR3ws::-webkit-scrollbar-thumb:active,
.StructEditor-module_intermediateCanvas__fR3ws::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.StructEditor-module_intermediateCanvas__fR3ws::-webkit-scrollbar {
  background-color: #fff;
}
.smallEditor .StructEditor-module_canvas__LLnzh .StructEditor-module_measureLog__B2wuy {
  right: 0;
  top: 0;
}
.ContextMenu-module_button-common-styles__pshYr {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.ContextMenu-module_scrollbar__fCla5 {
  scrollbar-width: thin;
}
.ContextMenu-module_scrollbar__fCla5 ::-webkit-scrollbar-track {
  background-color: transparent;
}
.ContextMenu-module_scrollbar__fCla5::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.ContextMenu-module_scrollbar__fCla5::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.ContextMenu-module_scrollbar__fCla5::-webkit-scrollbar-thumb:active,
.ContextMenu-module_scrollbar__fCla5::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.ContextMenu-module_contextMenu__oqyL- {
  --contexify-menu-bgColor: #fff;
  --contexify-item-color: #585858;
  --contexify-activeItem-color: #585858;
  --contexify-activeItem-bgColor: #ddd;
  --contexify-rightSlot-color: #585858;
  --contexify-activeRightSlot-color: #585858;
  --contexify-arrow-color: #b4b9d6;
  --contexify-activeArrow-color: #b4b9d6;
  --contexify-menu-shadow: 0 2px 5px 0 rgba(51, 51, 51, 0.54);
  --contexify-zIndex: 1;
  --contexify-menu-minWidth: 160px;
  --contexify-menu-padding: 4px;
  --contexify-menu-radius: 4px;
  --contexify-menu-negatePadding: var(--contexify-menu-padding);
  --contexify-itemContent-padding: 3px 20px;
  --contexify-activeItem-radius: 0;
  line-height: 1.5;
}
.ContextMenu-module_contextMenu__oqyL- .ContextMenu-module_icon__KhXEk {
  height: 20px;
  margin: 0 10px 0 -10px;
  width: 20px;
}
.ContextMenu-module_contextMenu__oqyL- .contexify_submenu {
  max-height: 300px;
  overflow: auto;
  scrollbar-width: thin;
}
.ContextMenu-module_contextMenu__oqyL- .contexify_submenu ::-webkit-scrollbar-track {
  background-color: transparent;
}
.ContextMenu-module_contextMenu__oqyL- .contexify_submenu::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.ContextMenu-module_contextMenu__oqyL- .contexify_submenu::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.ContextMenu-module_contextMenu__oqyL- .contexify_submenu::-webkit-scrollbar-thumb:active,
.ContextMenu-module_contextMenu__oqyL- .contexify_submenu::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.ContextMenu-module_contextMenu__oqyL- .contexify_item-disabled {
  opacity: inherit;
}
.ContextMenu-module_contextMenu__oqyL- .contexify_item-disabled > div {
  opacity: 0.5;
}
.ContextMenu-module_contextMenu__oqyL- .contexify_item:not(:last-child):after {
  border-top: 1px solid #cad3dd;
  content: '';
  display: block;
  margin: 3px 0;
}
.ContextMenu-module_contextMenu__oqyL- .ContextMenu-module_subMenu__87kBE .contexify_rightSlot {
  margin-right: -15px;
}
.InfoPanel-module_infoPanel__irvZL {
  background: #fff;
  border: 1px solid #b4b9d6;
  border-radius: 3px;
  padding: 20px;
  pointer-events: none;
  position: absolute;
}
.InfoPanel-module_infoPanel__irvZL svg {
  overflow: visible !important;
}
.About-module_button-common-styles__XmSbT {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.About-module_scrollbar__4-AeI {
  scrollbar-width: thin;
}
.About-module_scrollbar__4-AeI ::-webkit-scrollbar-track {
  background-color: transparent;
}
.About-module_scrollbar__4-AeI::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.About-module_scrollbar__4-AeI::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.About-module_scrollbar__4-AeI::-webkit-scrollbar-thumb:active,
.About-module_scrollbar__4-AeI::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.About-module_dialog_body__ebQu- {
  background-color: #fff;
}
.About-module_about__ZVesh {
  color: #333;
  font-weight: 500;
  width: 430px;
}
.About-module_about__ZVesh > footer {
  border-top: 1px solid #e1e5ea;
  margin: 0;
  padding: 15px;
}
.About-module_about__ZVesh > div[class^='Dialog-module_dialog_body'] {
  display: flex;
  flex-direction: column;
}
.About-module_about__ZVesh a {
  color: #167782;
}
.About-module_about__ZVesh .About-module_body__8VOc3 {
  border-radius: 6px;
  height: 174px;
  margin-right: 3px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2px 72px;
  scrollbar-width: thin;
}
.About-module_about__ZVesh .About-module_body__8VOc3 ::-webkit-scrollbar-track {
  background-color: transparent;
}
.About-module_about__ZVesh .About-module_body__8VOc3::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.About-module_about__ZVesh .About-module_body__8VOc3::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.About-module_about__ZVesh .About-module_body__8VOc3::-webkit-scrollbar-thumb:active,
.About-module_about__ZVesh .About-module_body__8VOc3::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.About-module_about__ZVesh .About-module_body__8VOc3 .About-module_versionName__1gqJr {
  font-weight: 600;
  margin-bottom: 2px;
}
.About-module_about__ZVesh .About-module_body__8VOc3 .About-module_firstline__h5XMW {
  display: inline-block;
}
.About-module_about__ZVesh .About-module_body__8VOc3 .About-module_links__sMpyx {
  text-align: right;
}
.About-module_about__ZVesh .About-module_body__8VOc3 .About-module_infoLinks__4-Tot {
  margin-top: 5px;
}
.About-module_about__ZVesh .About-module_body__8VOc3 .About-module_indigoVersion__WKFlu {
  display: flex;
  gap: 2px;
  margin-top: 20px;
}
.About-module_about__ZVesh .About-module_headerContent__YclOx {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}
.About-module_about__ZVesh .About-module_headerContent__YclOx a {
  align-items: center;
  color: #333;
  display: flex;
  gap: 10px;
  text-decoration: none;
}
.About-module_about__ZVesh .About-module_headerContent__YclOx .About-module_title__k-6Zg {
  font-size: 20px;
  line-height: 22px;
}
.About-module_about__ZVesh .About-module_indigoFirstLine__-86rb {
  display: inline-block;
}
.About-module_about__ZVesh dd {
  margin: 0 0 0.2em;
}
.About-module_about__ZVesh dt {
  margin-top: 3px;
}
.About-module_about__ZVesh .About-module_okButton__FMcEk {
  align-items: center;
  background-color: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.About-module_about__ZVesh .About-module_okButton__FMcEk:active,
.About-module_about__ZVesh .About-module_okButton__FMcEk:hover {
  border: 1px solid #333;
  color: #333;
}
.About-module_about__ZVesh .About-module_okButton__FMcEk:disabled {
  border: 1px solid rgba(88, 88, 88, 0.7);
  color: rgba(88, 88, 88, 0.7);
}
.Input-module_fieldSetLabel__pPlSl {
  position: relative;
}
.Input-module_input__tcHBd[type='checkbox'],
.Input-module_input__tcHBd[type='radio'] {
  opacity: 0;
  position: absolute;
}
.Input-module_input__tcHBd[type='checkbox'] + .Input-module_checkbox__dth7G {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='.5' width='15' height='15' rx='3.5' fill='%23fff' stroke='%23B4B9D6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 16px;
  margin-right: 6px;
  vertical-align: middle;
  width: 16px;
}
.Input-module_input__tcHBd[type='checkbox']:hover + .Input-module_checkbox__dth7G {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.75' y='.75' width='14.5' height='14.5' rx='3.25' fill='%23fff' stroke='%2343B5C0' stroke-width='1.5'/%3E%3C/svg%3E");
}
.Input-module_input__tcHBd[type='checkbox']:checked + .Input-module_checkbox__dth7G {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='4' fill='%23167782'/%3E%3Cpath d='M6.337 11.808 2.426 7.63l.973-.911L6.37 9.89l6.248-6.248.943.942-7.223 7.223Z' fill='%23fff'/%3E%3C/svg%3E");
}
.Input-module_input__tcHBd[type='radio'] + .Input-module_radioButton__WNnzl {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7' stroke='%23b4b9d6' stroke-width='2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
  width: 16px;
}
.Input-module_input__tcHBd[type='radio']:hover + .Input-module_radioButton__WNnzl {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7' stroke='%234fb3bf' stroke-width='2'/%3E%3C/svg%3E");
}
.Input-module_input__tcHBd[type='radio']:checked + .Input-module_radioButton__WNnzl {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='%23167782' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7' stroke='%23167782' stroke-width='2'/%3E%3Ccircle cx='8' cy='8' r='4'/%3E%3C/svg%3E");
}
.Input-module_input__tcHBd[type='radio']:disabled + .Input-module_radioButton__WNnzl {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7' stroke='%23b4b9d6' stroke-width='2'/%3E%3C/svg%3E");
}
.Input-module_input__tcHBd[type='radio']:checked:disabled + .Input-module_radioButton__WNnzl {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='%23b4b9d6' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7' stroke='%23b4b9d6' stroke-width='2'/%3E%3Ccircle cx='8' cy='8' r='4'/%3E%3C/svg%3E");
}
.Input-module_slider__ws6m0.Input-module_slider__ws6m0 {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 38px;
}
.Input-module_slider__ws6m0.Input-module_slider__ws6m0 input {
  height: 0;
  opacity: 0;
  width: 0;
}
.Input-module_slider__ws6m0.Input-module_slider__ws6m0 span {
  background-color: hsla(0, 0%, 100%, 0.3);
  border: 1px solid #cad3dd;
  border-radius: 12px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background 0.4s;
}
.Input-module_slider__ws6m0.Input-module_slider__ws6m0 span:before {
  background-color: #c4c4c4;
  border-radius: 50%;
  bottom: 1px;
  content: '';
  height: 16px;
  left: 1px;
  position: absolute;
  transition: transform 0.4s ease-in 0s;
  width: 16px;
}
.Input-module_slider__ws6m0.Input-module_slider__ws6m0 input:checked + span {
  background-color: #167782;
}
.Input-module_slider__ws6m0.Input-module_slider__ws6m0 input:checked + span:before {
  background-color: #fff;
  transform: translateX(18px);
}
.Input-module_genericInput__yT-J9,
.Input-module_select__z9CHU {
  color: #333;
}
.Input-module_select__z9CHU {
  min-width: 40px;
}
.form-module_button-common-styles__BvKSn {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.form-module_scrollbar__MgLtc {
  scrollbar-width: thin;
}
.form-module_scrollbar__MgLtc ::-webkit-scrollbar-track {
  background-color: transparent;
}
.form-module_scrollbar__MgLtc::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.form-module_scrollbar__MgLtc::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.form-module_scrollbar__MgLtc::-webkit-scrollbar-thumb:active,
.form-module_scrollbar__MgLtc::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.form-module_dataError__IXK1h .form-module_inputWrapper__sIDHH {
  display: inline-block;
  position: relative;
}
.form-module_dataError__IXK1h .form-module_inputWrapper__sIDHH:after {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='12' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.001 14a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM6.668 2h2.667v8H6.668V2Z' fill='%23F40724'/%3E%3C/svg%3E");
  position: absolute;
  right: -10px;
  top: 7px;
}
.form-module_dataError__IXK1h input[type='number'],
.form-module_dataError__IXK1h input[type='search'],
.form-module_dataError__IXK1h input[type='text'],
.form-module_dataError__IXK1h select,
.form-module_dataError__IXK1h textarea {
  border: 1px solid #f40724 !important;
}
.form-module_dataError__IXK1h input[type='number']:focus,
.form-module_dataError__IXK1h input[type='number']:hover,
.form-module_dataError__IXK1h input[type='search']:focus,
.form-module_dataError__IXK1h input[type='search']:hover,
.form-module_dataError__IXK1h input[type='text']:focus,
.form-module_dataError__IXK1h input[type='text']:hover,
.form-module_dataError__IXK1h select:focus,
.form-module_dataError__IXK1h select:hover,
.form-module_dataError__IXK1h textarea:focus,
.form-module_dataError__IXK1h textarea:hover {
  box-shadow: inset 0 0 1px 1px rgba(244, 7, 36, 0.12);
}
.ColorPicker-module_colorPickerInput__voi2r {
  align-items: center;
  background-color: #fff;
  border: 1px solid #cad3dd;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  display: flex;
  font-family:
    Inter,
    FreeSans,
    Arimo,
    Droid Sans,
    Helvetica,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
  height: 24px;
  min-height: 24px;
  padding-left: 3px;
  padding-right: 7px;
  width: 48px;
}
.ColorPicker-module_colorPickerInput__voi2r:hover {
  border-color: #43b5c0;
}
.ColorPicker-module_selectedInput__Ykcjd {
  border-color: #167782;
}
.ColorPicker-module_colorPickerWrap__IAQVo {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 10px rgba(103, 104, 132, 0.15);
  height: 35px;
  left: 0;
  padding: 8px;
  position: absolute;
  top: 24px;
  width: 70px;
  z-index: 1;
}
.ColorPicker-module_withPalette__gTwNu {
  height: 140px;
}
.ColorPicker-module_colorPicker__Uz3PM.ColorPicker-module_colorPicker__Uz3PM {
  height: 80%;
  right: 0;
  top: 0;
}
.ColorPicker-module_colorPicker__Uz3PM.ColorPicker-module_colorPicker__Uz3PM input {
  border: 1px solid transparent !important;
  color: #333;
  float: right;
  font-size: 10px;
  height: 12px !important;
  margin-top: 2px;
  outline: transparent;
  padding: 1px 2px !important;
  position: relative;
  text-align: right;
  text-transform: uppercase;
  width: 60%;
}
.ColorPicker-module_colorPicker__Uz3PM.ColorPicker-module_colorPicker__Uz3PM input:focus {
  border: 1px solid #ddd !important;
}
.ColorPicker-module_colorPicker__Uz3PM.ColorPicker-module_colorPicker__Uz3PM div {
  border-radius: 0;
}
.ColorPicker-module_colorPicker__Uz3PM.ColorPicker-module_colorPicker__Uz3PM .react-colorful {
  height: 80%;
  width: 100%;
}
.ColorPicker-module_colorPicker__Uz3PM.ColorPicker-module_colorPicker__Uz3PM .react-colorful__hue {
  height: 15px;
}
.ColorPicker-module_colorPicker__Uz3PM.ColorPicker-module_colorPicker__Uz3PM .react-colorful__pointer {
  border-radius: 50%;
  border-width: 1px;
  height: 10px;
  width: 10px;
}
.ColorPicker-module_colorPickerBtn__nr6ph {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.ColorPicker-module_colorPickerPreview__-Rf-i {
  border: none;
  border-radius: 2px;
  height: 16px;
  width: 16px;
}
.ColorPicker-module_colorPickerOverlay__WzC-w {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -10;
}
.ColorPicker-module_colorContainer__ib5up {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}
.ColorPicker-module_colorContainer__ib5up .ColorPicker-module_hex__mE9my {
  color: #333;
  font-size: 10px;
}
.ColorPicker-module_presetColors__J-ysq {
  display: flex;
  flex-wrap: wrap;
  width: 78px;
}
.ColorPicker-module_presetColors__J-ysq button {
  border: none;
  border-radius: 2px;
  height: 16px;
  margin-bottom: 2px;
  margin-right: 2px;
  width: 16px;
}
.ColorPicker-module_presetColors__J-ysq button:last-of-type,
.ColorPicker-module_presetColors__J-ysq button:nth-of-type(4) {
  margin-right: 0;
}
.ColorPicker-module_presetColors__J-ysq .ColorPicker-module_chooseColor__fdzt8 {
  background-color: #fff;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iLjUiIHk9Ii41IiB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHJ4PSIxLjUiIHN0cm9rZT0iIzAwNTY2MiIvPjxwYXRoIGZpbGw9IiMwMDU2NjIiIGQ9Ik03LjM0NiAzLjQyOGgxLjMwNnY5LjE0M0g3LjM0NnoiLz48cGF0aCBmaWxsPSIjMDA1NjYyIiBkPSJNMy40MjggOC42NTNWNy4zNDdoOS4xNDN2MS4zMDZ6Ii8+PC9zdmc+');
  background-position: 50%;
  background-repeat: no-repeat;
  border: 1px solid #00838f;
}
.ColorPicker-module_presetColors__J-ysq .ColorPicker-module_clicked__8BNoN {
  background-color: #00838f;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTQuMzQ2LjQyOWgxLjMwNnY5LjE0M0g0LjM0NnoiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNLjQyOCA1LjY1M1Y0LjM0N0g5LjU3djEuMzA2eiIvPjwvc3ZnPg==');
}
.ColorPicker-module_expandIcon__oH4ka {
  margin-left: auto;
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
  width: 16px;
}
.ColorPicker-module_turnedIcon__-zaq- {
  transform: rotate(0deg);
}
.ColorPicker-module_colorPickerWrapper__GJm87 {
  position: relative;
}
.measure-input-module_measureInput__uZQ-j {
  align-items: center;
  color: #333;
  display: inline-flex;
  font-size: 12px;
  justify-content: space-between;
  line-height: 20px;
  margin-bottom: 16px;
  width: 100%;
}
.measure-input-module_measureInput__uZQ-j .measure-input-module_select__1ZFmv,
.measure-input-module_measureInput__uZQ-j input {
  height: 24px;
  margin-left: 4px;
  width: 58px;
}
.buttons-module_button-common-styles__qWDOz {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.buttons-module_scrollbar__SpaVV {
  scrollbar-width: thin;
}
.buttons-module_scrollbar__SpaVV ::-webkit-scrollbar-track {
  background-color: transparent;
}
.buttons-module_scrollbar__SpaVV::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.buttons-module_scrollbar__SpaVV::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.buttons-module_scrollbar__SpaVV::-webkit-scrollbar-thumb:active,
.buttons-module_scrollbar__SpaVV::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.buttons-module_openButton__wErm9 {
  float: left;
}
.Accordion-module_button-common-styles__enTfZ {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Accordion-module_scrollbar__lJTa6 {
  scrollbar-width: thin;
}
.Accordion-module_scrollbar__lJTa6 ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Accordion-module_scrollbar__lJTa6::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Accordion-module_scrollbar__lJTa6::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Accordion-module_scrollbar__lJTa6::-webkit-scrollbar-thumb:active,
.Accordion-module_scrollbar__lJTa6::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Accordion-module_accordionWrapper__RKk00 {
  background-color: #eff2f5;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.Accordion-module_accordionWrapper__RKk00 ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Accordion-module_accordionWrapper__RKk00::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Accordion-module_accordionWrapper__RKk00::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Accordion-module_accordionWrapper__RKk00::-webkit-scrollbar-thumb:active,
.Accordion-module_accordionWrapper__RKk00::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Accordion-module_expandIcon__ncUKv {
  margin-right: 12px;
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
  width: 16px;
}
.Accordion-module_turnedIcon__v1sCA {
  transform: rotate(0deg);
}
.Accordion-module_accordionSummaryWrapper__roIRd {
  background-color: #fff;
  border-bottom: 1px solid #e1e5ea;
  margin: 0;
  padding-left: 12px;
}
.Accordion-module_accordionSummary__1Gq0d {
  align-items: center;
  color: #333;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  justify-content: flex-start;
  line-height: 14px;
  overflow: hidden;
  width: 300px;
}
.Accordion-module_accordionDetailsWrapper__fnSnc {
  max-height: 1000px;
  overflow: hidden;
  padding: 0 12px;
  transition: max-height 0.3s ease-in-out;
  width: 300px;
}
.Accordion-module_hiddenAccordion__WZFcJ {
  max-height: 0;
}
.Accordion-module_accordionDetails__g2RM0 {
  color: #333;
  margin-top: 12px;
  padding-bottom: 4px;
}
.Accordion-module_groupIcon__1g1lx {
  height: 15px;
  width: 15px;
}
.Accordion-module_groupLabel__23y4U {
  align-items: center;
  display: flex;
  height: 15px;
}
.Accordion-module_groupLabel__23y4U > span {
  height: 12px;
  margin-left: 6px;
}
.Accordion-module_changeMarker__UPtGx {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%23167782'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 10px;
  margin-left: auto;
  margin-right: 1px;
  vertical-align: middle;
  width: 10px;
}
.Settings-module_headerContent__wBYFf {
  display: flex;
  gap: 8px;
}
.Settings-module_headerContent__wBYFf .Settings-module_title__a6mod {
  padding-right: 4px;
}
.Settings-module_headerContent__wBYFf .Settings-module_button__VZ2w7 {
  background-color: transparent;
  border: none;
  color: #525252;
  font-size: 0;
  padding: 0;
}
.Settings-module_headerContent__wBYFf .Settings-module_button__VZ2w7 input[type='file'] {
  display: none;
}
.Settings-module_headerContent__wBYFf .Settings-module_button__VZ2w7:hover {
  color: #005662;
}
.Settings-module_headerContent__wBYFf .Settings-module_button__VZ2w7:disabled {
  color: rgba(82, 82, 82, 0.4);
}
.Settings-module_accordion__1uSYy fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.Settings-module_accordion__1uSYy ul > li {
  padding: 10px 16px;
}
.Settings-module_accordion__1uSYy label {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: flex-start;
  line-height: 14px;
  margin-bottom: 12px;
}
.Settings-module_accordion__1uSYy label input[type='checkbox'] {
  top: 0;
}
.Settings-module_accordion__1uSYy label input,
.Settings-module_accordion__1uSYy label select,
.Settings-module_accordion__1uSYy label span > div {
  margin-right: auto;
  width: 120px;
}
.Settings-module_accordion__1uSYy label > :first-child {
  margin-right: 12px;
  width: 168px;
}
.Settings-module_settings__Z5btc {
  height: 70vh;
  overflow: hidden;
  width: 324px;
}
.Settings-module_settings__Z5btc fieldset[disabled] label > span {
  opacity: 0.3;
}
.Settings-module_settings__Z5btc fieldset[disabled] label > div {
  opacity: 0.6;
}
.Settings-module_settings__Z5btc > header {
  box-shadow: 0 2px 4px 0 rgba(1, 1, 50, 0.1);
  flex-shrink: 0;
  z-index: 10;
}
.Settings-module_settings__Z5btc > footer {
  box-shadow: 0 -2px 4px 0 rgba(1, 1, 50, 0.1);
  z-index: 10;
}
.Check-module_button-common-styles__WPocW {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Check-module_scrollbar__3jzOc {
  scrollbar-width: thin;
}
.Check-module_scrollbar__3jzOc ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Check-module_scrollbar__3jzOc::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Check-module_scrollbar__3jzOc::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Check-module_scrollbar__3jzOc::-webkit-scrollbar-thumb:active,
.Check-module_scrollbar__3jzOc::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Check-module_dialog_body__wHZMM {
  width: 444px;
}
.Check-module_dialog_body__wHZMM > * {
  background-color: #fff;
}
.Check-module_dialog_body__wHZMM > div {
  margin: 0;
  overflow: auto;
}
.Check-module_dialog_body__wHZMM > footer {
  border-radius: inherit;
}
.Check-module_dialog_body__wHZMM form {
  padding-top: 10px;
}
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO .Check-module_buttonSecondary__2482r {
  align-items: center;
  background-color: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO .Check-module_buttonSecondary__2482r:active,
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO .Check-module_buttonSecondary__2482r:hover {
  border: 1px solid #333;
  color: #333;
}
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO .Check-module_buttonSecondary__2482r:disabled {
  border: 1px solid rgba(88, 88, 88, 0.7);
  color: rgba(88, 88, 88, 0.7);
}
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO .Check-module_buttonPrimary__1h5p1 {
  align-items: center;
  background-color: #167782;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO .Check-module_buttonPrimary__1h5p1:active,
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO .Check-module_buttonPrimary__1h5p1:hover {
  background-color: #4fb3bf;
}
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO .Check-module_buttonPrimary__1h5p1:disabled {
  background-color: #e1e5ea;
  color: #333;
}
.Check-module_dialog_body__wHZMM .Check-module_buttons__cjuqO > .Check-module_buttonsRight__L1v9A {
  display: flex;
  gap: 8px;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp {
  -moz-column-gap: 15px;
  column-gap: 15px;
  display: flex;
  flex-direction: row;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_checkInfo__Iqq6t {
  flex-grow: 1;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_settings__knuQF {
  margin-left: 12px;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_centeredContainer__t61r8,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_settings__knuQF,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warnings__b4ozd {
  color: #333;
  min-width: 130px;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_centeredContainer__t61r8 fieldset,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_settings__knuQF fieldset,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warnings__b4ozd fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
  padding: 0;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_centeredContainer__t61r8 fieldset li,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_settings__knuQF fieldset li,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warnings__b4ozd fieldset li {
  margin: 9px 0 0;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_centeredContainer__t61r8 fieldset li label,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_settings__knuQF fieldset li label,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warnings__b4ozd fieldset li label {
  align-items: center;
  display: flex;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_centeredContainer__t61r8 fieldset div,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_settings__knuQF fieldset div,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warnings__b4ozd fieldset div {
  margin: 7px 3px 17px 12px;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_centeredContainer__t61r8 fieldset div dt,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_settings__knuQF fieldset div dt,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warnings__b4ozd fieldset div dt {
  font-weight: 700;
  margin-bottom: 4px;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_centeredContainer__t61r8 fieldset div dd,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_settings__knuQF fieldset div dd,
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warnings__b4ozd fieldset div dd {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  word-break: break-word;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warnings__b4ozd {
  background-color: #e1e5ea;
  width: 100%;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_warningsContainer__vSbyK {
  height: 287px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.Check-module_dialog_body__wHZMM
  .Check-module_wrapper__27SOp
  .Check-module_warningsContainer__vSbyK
  ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Check-module_dialog_body__wHZMM
  .Check-module_wrapper__27SOp
  .Check-module_warningsContainer__vSbyK::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Check-module_dialog_body__wHZMM
  .Check-module_wrapper__27SOp
  .Check-module_warningsContainer__vSbyK::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Check-module_dialog_body__wHZMM
  .Check-module_wrapper__27SOp
  .Check-module_warningsContainer__vSbyK::-webkit-scrollbar-thumb:active,
.Check-module_dialog_body__wHZMM
  .Check-module_wrapper__27SOp
  .Check-module_warningsContainer__vSbyK::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Check-module_dialog_body__wHZMM .Check-module_wrapper__27SOp .Check-module_centeredContainer__t61r8 {
  align-items: center;
  background-color: #e1e5ea;
  display: flex;
  height: 287px;
  justify-content: center;
  width: 100%;
}
.Check-module_dialog_body__wHZMM .Check-module_checkBoxesDisabled__EAdRo {
  opacity: 0.5;
}
.FormulaInput-module_button-common-styles__xQ8ZA {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.FormulaInput-module_scrollbar__8TUkb {
  scrollbar-width: thin;
}
.FormulaInput-module_scrollbar__8TUkb ::-webkit-scrollbar-track {
  background-color: transparent;
}
.FormulaInput-module_scrollbar__8TUkb::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.FormulaInput-module_scrollbar__8TUkb::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.FormulaInput-module_scrollbar__8TUkb::-webkit-scrollbar-thumb:active,
.FormulaInput-module_scrollbar__8TUkb::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.FormulaInput-module_chem_input__oprB3 {
  background: #fff;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  height: 2.4em;
  height: 32px;
  line-height: 1.42857143;
  margin: 2px;
  overflow: hidden;
  padding: 0.35em 0.15em 0.35em 0.3em;
  vertical-align: middle;
  white-space: nowrap;
  width: 270px;
}
.FormulaInput-module_chem_input__oprB3:focus,
.FormulaInput-module_chem_input__oprB3:hover {
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.12);
}
.FormulaInput-module_chem_input__oprB3[disabled],
.FormulaInput-module_chem_input__oprB3[readonly],
fieldset[disabled] .FormulaInput-module_chem_input__oprB3 {
  background: #f3f3f3;
  cursor: not-allowed;
  opacity: 0.6;
}
.FormulaInput-module_chem_input__oprB3:active,
.FormulaInput-module_chem_input__oprB3:focus {
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.12);
  outline: 0;
}
.Analyse-module_button-common-styles__8No07 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Analyse-module_scrollbar__UAaIN {
  scrollbar-width: thin;
}
.Analyse-module_scrollbar__UAaIN ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Analyse-module_scrollbar__UAaIN::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Analyse-module_scrollbar__UAaIN::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Analyse-module_scrollbar__UAaIN::-webkit-scrollbar-thumb:active,
.Analyse-module_scrollbar__UAaIN::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Analyse-module_analyse__MR-Tu {
  width: 260px;
}
.Analyse-module_analyse__MR-Tu li:first-child > .Analyse-module_inputWrapper__uR995 > div {
  background-color: #e1e5ea;
  border: 1px solid #cad3dd;
  border-radius: 4px;
  box-shadow: none;
  cursor: text;
  font-size: 14px;
  height: 24px;
  line-height: 10px;
  margin: 0;
  padding-left: 8px;
  width: 236px;
}
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi label {
  margin-bottom: 4px;
}
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi .Analyse-module_inputWrapper__uR995 {
  display: flex;
  flex-direction: column;
}
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi .Analyse-module_inputWrapper__uR995 input,
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi .Analyse-module_inputWrapper__uR995 textarea {
  background-color: #e1e5ea;
  border: 1px solid #cad3dd;
  border-radius: 4px;
}
.Analyse-module_analyse__MR-Tu
  .Analyse-module_contentWrapper__B4WCi
  .Analyse-module_inputWrapper__uR995
  input[readonly],
.Analyse-module_analyse__MR-Tu
  .Analyse-module_contentWrapper__B4WCi
  .Analyse-module_inputWrapper__uR995
  textarea[readonly] {
  cursor: text;
}
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi .Analyse-module_inputWrapper__uR995 input {
  width: 131px;
}
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi .Analyse-module_inputWrapper__uR995 input:hover {
  border: 1px solid #cad3dd;
}
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi .Analyse-module_inputWrapper__uR995 textarea {
  height: 87px;
  letter-spacing: -1px;
  resize: none;
  width: 236px;
}
.Analyse-module_analyse__MR-Tu .Analyse-module_contentWrapper__B4WCi .Analyse-module_selectWrapper__xEkKL {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.Analyse-module_analyse__MR-Tu
  .Analyse-module_contentWrapper__B4WCi
  .Analyse-module_selectWrapper__xEkKL
  .Analyse-module_select__c6hFt {
  height: 24px;
  margin-top: 4px;
  width: 97px;
}
.Analyse-module_analyse__MR-Tu
  .Analyse-module_contentWrapper__B4WCi
  .Analyse-module_selectWrapper__xEkKL
  .Analyse-module_select__c6hFt
  fieldset {
  border: 1px solid #cad3dd;
  border-radius: 4px;
}
.Recognize-module_button-common-styles__B04E6 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Recognize-module_scrollbar__rohcP {
  scrollbar-width: thin;
}
.Recognize-module_scrollbar__rohcP ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Recognize-module_scrollbar__rohcP::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Recognize-module_scrollbar__rohcP::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Recognize-module_scrollbar__rohcP::-webkit-scrollbar-thumb:active,
.Recognize-module_scrollbar__rohcP::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Recognize-module_recognize__66jG2 .Recognize-module_footerContent__0MAC7 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Recognize-module_recognize__66jG2 .Recognize-module_footerContent__0MAC7 div {
  display: flex;
  gap: 8px;
}
.Recognize-module_recognize__66jG2 .Recognize-module_imagoVersion__nBIj6 {
  display: flex;
  flex-direction: column;
  grid-column-end: 3;
  grid-column-start: 2;
}
.Recognize-module_recognize__66jG2 .Recognize-module_openButton__H-j5I {
  align-items: center;
  background-color: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-flex;
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Recognize-module_recognize__66jG2 .Recognize-module_openButton__H-j5I:active,
.Recognize-module_recognize__66jG2 .Recognize-module_openButton__H-j5I:hover {
  border: 1px solid #333;
  color: #333;
}
.Recognize-module_recognize__66jG2 .Recognize-module_openButton__H-j5I:disabled {
  border: 1px solid rgba(88, 88, 88, 0.7);
  color: rgba(88, 88, 88, 0.7);
}
.Recognize-module_recognize__66jG2 .Recognize-module_openButton__H-j5I > input {
  display: none;
}
.Recognize-module_recognize__66jG2 .Recognize-module_openButton__H-j5I > svg {
  height: 16px;
  width: 16px;
}
.Recognize-module_recognize__66jG2 .Recognize-module_imagesContainer__rXWWk {
  background-color: #eff2f5;
  display: flex;
  gap: 12px;
  padding: 12px;
}
.Recognize-module_recognize__66jG2 .Recognize-module_topBody__ZCCjN {
  grid-column-gap: 12px;
  grid-row-gap: 24px;
  display: grid;
  font-size: 10px;
  grid-template-columns: 1fr 1fr;
  line-height: 12px;
  margin: 8px 0 4px;
  padding: 0 12px;
}
.Recognize-module_recognize__66jG2 .Recognize-module_topBody__ZCCjN > label > select {
  font-size: inherit;
}
.Recognize-module_recognize__66jG2 .Recognize-module_output__WgRnP,
.Recognize-module_recognize__66jG2 .Recognize-module_picture__x2Y7S {
  background-color: #fff;
  border: 1px solid #d9dcea;
  border-radius: 6px;
  box-sizing: border-box;
  height: 192px;
  overflow: hidden;
  text-align: center;
  width: 232px;
}
.Recognize-module_recognize__66jG2 .Recognize-module_picture__x2Y7S {
  align-items: center;
  color: #717171;
  display: flex;
  justify-content: center;
  position: relative;
}
.Recognize-module_recognize__66jG2 .Recognize-module_filename__-1HIU {
  left: 8px;
  position: absolute;
  top: 8px;
}
.Recognize-module_recognize__66jG2 .Recognize-module_messageContainer__tMvyb {
  align-items: center;
  background-color: #eff2f5;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.Recognize-module_recognize__66jG2 .Recognize-module_messageContainer__tMvyb > p {
  margin: 12px;
}
.Recognize-module_recognize__66jG2 .Recognize-module_primaryButton__rzm03 {
  align-items: center;
  background-color: #167782;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Recognize-module_recognize__66jG2 .Recognize-module_primaryButton__rzm03:active,
.Recognize-module_recognize__66jG2 .Recognize-module_primaryButton__rzm03:hover {
  background-color: #4fb3bf;
}
.Recognize-module_recognize__66jG2 .Recognize-module_primaryButton__rzm03:disabled {
  background-color: #e1e5ea;
  color: #333;
}
.Recognize-module_recognize__66jG2 .Recognize-module_secondaryButton__H1Q28 {
  align-items: center;
  background-color: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Recognize-module_recognize__66jG2 .Recognize-module_secondaryButton__H1Q28:active,
.Recognize-module_recognize__66jG2 .Recognize-module_secondaryButton__H1Q28:hover {
  border: 1px solid #333;
  color: #333;
}
.Recognize-module_recognize__66jG2 .Recognize-module_secondaryButton__H1Q28:disabled {
  border: 1px solid rgba(88, 88, 88, 0.7);
  color: rgba(88, 88, 88, 0.7);
}
.Recognize-module_recognize__66jG2 .Recognize-module_struct__W55TL,
.Recognize-module_recognize__66jG2 img {
  max-height: 100%;
  max-width: 100%;
  position: relative;
}
.Miew-module_button-common-styles__8FE-p {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Miew-module_scrollbar__ZMWH- {
  scrollbar-width: thin;
}
.Miew-module_scrollbar__ZMWH- ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Miew-module_scrollbar__ZMWH-::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Miew-module_scrollbar__ZMWH-::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Miew-module_scrollbar__ZMWH-::-webkit-scrollbar-thumb:active,
.Miew-module_scrollbar__ZMWH-::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Miew-module_warning__IDl5F {
  line-height: 2em;
  width: 100%;
}
.Miew-module_applyButton__ZDE-i {
  align-items: center;
  background-color: #167782;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Miew-module_applyButton__ZDE-i:active,
.Miew-module_applyButton__ZDE-i:hover {
  background-color: #4fb3bf;
}
.Miew-module_applyButton__ZDE-i:disabled {
  background-color: #e1e5ea;
  color: #333;
}
.Miew-module_miewDialog__gHvYc {
  max-width: 60vw;
}
.Miew-module_miewContainer__2SNw3 {
  height: 60vh;
  overflow: hidden;
  position: relative;
  width: 60vw;
}
.Miew-module_miewContainer__2SNw3 .mode-message {
  align-items: flex-start;
  background-color: rgba(64, 64, 64, 0.75);
  border-radius: 4px;
  color: silver;
  display: flex;
  justify-content: left;
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 10px;
}
.Miew-module_miewContainer__2SNw3 .atom-info {
  color: #333;
  margin: 12px;
  position: absolute;
  top: 0;
}
.Miew-module_miewContainer__2SNw3 .overlay {
  pointer-events: none;
}
.Miew-module_loadingContainer__cVF-M {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.Miew-module_miewDarkTheme__kMFzc .atom-info {
  color: #cad3dd;
}
.Atom-module_button-common-styles__ipWaI {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Atom-module_scrollbar__z5cVx {
  scrollbar-width: thin;
}
.Atom-module_scrollbar__z5cVx ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Atom-module_scrollbar__z5cVx::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Atom-module_scrollbar__z5cVx::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Atom-module_scrollbar__z5cVx::-webkit-scrollbar-thumb:active,
.Atom-module_scrollbar__z5cVx::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Atom-module_atomProps__pqBjY {
  display: flex;
  height: 386px;
  justify-content: space-between;
  overflow: hidden;
  width: 220px;
}
.Atom-module_atomProps__pqBjY .MuiOutlinedInput-root,
.Atom-module_atomProps__pqBjY input[type='text'] {
  margin-top: 4px;
}
.Atom-module_atomProps__pqBjY > header {
  box-shadow: 0 2px 4px 0 rgba(1, 1, 50, 0.1);
  flex-shrink: 0;
  z-index: 10;
}
.Atom-module_atomProps__pqBjY > div {
  background-color: #eff2f5;
  flex-grow: 1;
  margin: 0;
}
.Atom-module_atomProps__pqBjY > footer {
  box-shadow: 0 -2px 4px 0 rgba(1, 1, 50, 0.1);
  z-index: 10;
}
.Atom-module_accordionWrapper__MSuU7 {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.Atom-module_accordionWrapper__MSuU7 ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Atom-module_accordionWrapper__MSuU7::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Atom-module_accordionWrapper__MSuU7::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Atom-module_accordionWrapper__MSuU7::-webkit-scrollbar-thumb:active,
.Atom-module_accordionWrapper__MSuU7::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Atom-module_expandIcon__mPszC {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
  width: 20px;
}
.Atom-module_turnedIcon__0Kzwy {
  transform: rotate(0deg);
}
.Atom-module_accordionSummaryWrapper__MGqsq {
  background-color: #fff;
  border-bottom: 1px solid #e1e5ea;
  margin: 0;
  padding-left: 11.5px;
}
.Atom-module_accordionSummary__HMKzl {
  align-items: center;
  color: #333;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 400;
  height: 26px;
  justify-content: space-between;
  line-height: 14px;
  overflow: hidden;
  width: 197px;
}
.Atom-module_accordionDetailsWrapper__p6EI7 {
  max-height: 1000px;
  overflow: hidden;
  padding: 0 12px;
  transition: max-height 0.3s ease-in-out;
  width: 196px;
}
.Atom-module_hiddenAccordion__pDNdM {
  max-height: 0;
}
.Atom-module_accordionDetails__-B3jK {
  color: #333;
  margin-top: 12px;
  padding-bottom: 8px;
  position: relative;
}
.Atom-module_accordionDetails__-B3jK label {
  align-items: center;
  color: #585858;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 4px;
}
.Atom-module_accordionDetails__-B3jK label > :last-child > *,
.Atom-module_accordionDetails__-B3jK label > input {
  margin: 0 !important;
  width: 137px;
}
.Atom-module_accordionDetails__-B3jK label.Atom-module_checkbox__Vup40 {
  align-items: center;
  flex-direction: row;
  height: 16px;
  justify-content: space-between;
  margin-top: 8px;
}
.Atom-module_querySpecific__7XxMr label > :last-child > *,
.Atom-module_reactionFlags__gdNb1 label > :last-child > * {
  width: 77px;
}
.Atom-module_reactionFlags__gdNb1 {
  padding-bottom: 20px;
}
.Attach-module_attachPoints__5QEAv {
  width: 260px;
}
.Attach-module_attachPoints__5QEAv > div {
  margin-top: 12px;
}
.Attach-module_attachPoints__5QEAv form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 17em;
}
.Attach-module_attachPoints__5QEAv form label {
  align-items: center;
  color: #585858;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.Automap-module_automap__531ko {
  width: 260px;
}
.Automap-module_automap__531ko label {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 4px;
  line-height: 14px;
  margin: 12px 0;
}
.Automap-module_automap__531ko label > div {
  flex-grow: 1;
}
.Bond-module_bond__EDfLY {
  width: 260px;
}
.Bond-module_bond__EDfLY > div {
  margin-top: 12px;
}
.Bond-module_bond__EDfLY label {
  color: #585858;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 12px;
}
.Bond-module_bond__EDfLY label > span {
  margin-bottom: 4px;
}
.IfThenSelect-module_field__9YaPA {
  display: block;
  gap: 4px;
}
.RgroupLogic-module_button-common-styles__P-ABP {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.RgroupLogic-module_scrollbar__gDniy {
  scrollbar-width: thin;
}
.RgroupLogic-module_scrollbar__gDniy ::-webkit-scrollbar-track {
  background-color: transparent;
}
.RgroupLogic-module_scrollbar__gDniy::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.RgroupLogic-module_scrollbar__gDniy::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.RgroupLogic-module_scrollbar__gDniy::-webkit-scrollbar-thumb:active,
.RgroupLogic-module_scrollbar__gDniy::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.RgroupLogic-module_rgroupLogic__q4RTy {
  width: 260px;
}
.RgroupLogic-module_rgroupLogic__q4RTy > div {
  margin-top: 12px;
}
.RgroupLogic-module_rgroupLogic__q4RTy label {
  color: #585858;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 12px;
}
.RgroupLogic-module_rgroupLogic__q4RTy label input,
.RgroupLogic-module_rgroupLogic__q4RTy label select {
  margin: 4px 0 0;
}
.RgroupLogic-module_rgroupLogic__q4RTy label.RgroupLogic-module_resth__RL2qE {
  align-items: center;
  flex-direction: row;
  margin-bottom: 24px;
  width: -moz-min-content;
  width: min-content;
}
.Open-module_button-common-styles__NBSQP {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Open-module_scrollbar__sRvYM {
  scrollbar-width: thin;
}
.Open-module_scrollbar__sRvYM ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Open-module_scrollbar__sRvYM::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Open-module_scrollbar__sRvYM::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Open-module_scrollbar__sRvYM::-webkit-scrollbar-thumb:active,
.Open-module_scrollbar__sRvYM::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Open-module_open__m8V9g {
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(103, 104, 132, 0.25);
}
.Open-module_open__m8V9g > div {
  margin: 0;
}
.Open-module_open__m8V9g label {
  display: block;
  width: 22.5em;
}
.Open-module_open__m8V9g label input {
  margin-bottom: 0.1em;
}
.Open-module_open__m8V9g .Open-module_textareaEditor__ZbEVj {
  border: none;
  border-bottom: 1px solid #e1e5ea;
  border-radius: 0;
  min-height: 23em;
  min-width: 32em;
  overflow: auto;
  padding: 12px;
  resize: both;
  scrollbar-width: thin;
  white-space: pre;
  width: 560px;
}
.Open-module_open__m8V9g .Open-module_textareaEditor__ZbEVj ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Open-module_open__m8V9g .Open-module_textareaEditor__ZbEVj::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Open-module_open__m8V9g .Open-module_textareaEditor__ZbEVj::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Open-module_open__m8V9g .Open-module_textareaEditor__ZbEVj::-webkit-scrollbar-thumb:active,
.Open-module_open__m8V9g .Open-module_textareaEditor__ZbEVj::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Open-module_open__m8V9g .Open-module_textareaEditor__ZbEVj:hover {
  border: none;
  border-bottom: 1px solid #e1e5ea;
  border-radius: 0;
}
.Open-module_footerContent__Q0hTw {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Open-module_buttonsContainer__L1HqU {
  display: flex;
  gap: 8px;
}
.Open-module_cancelButton__0TQzA,
.Open-module_openButton__cj3oU {
  align-items: center;
  background-color: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Open-module_cancelButton__0TQzA:active,
.Open-module_cancelButton__0TQzA:hover,
.Open-module_openButton__cj3oU:active,
.Open-module_openButton__cj3oU:hover {
  border: 1px solid #333;
  color: #333;
}
.Open-module_cancelButton__0TQzA:disabled,
.Open-module_openButton__cj3oU:disabled {
  border: 1px solid rgba(88, 88, 88, 0.7);
  color: rgba(88, 88, 88, 0.7);
}
.Open-module_copyButton__KlXKd {
  align-items: center;
  background-color: #167782;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Open-module_copyButton__KlXKd:active,
.Open-module_copyButton__KlXKd:hover {
  background-color: #4fb3bf;
}
.Open-module_copyButton__KlXKd:disabled {
  background-color: #e1e5ea;
  color: #333;
}
.AnalyzingFile-module_analyzingFileWrapper__j0Ltf {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 23em;
  width: 410px;
}
.AnalyzingFile-module_analyzingFileWrapper__j0Ltf .AnalyzingFile-module_fileBox__ywFIh {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.AnalyzingFile-module_analyzingFileWrapper__j0Ltf .AnalyzingFile-module_fileBox__ywFIh svg {
  margin-right: 13px;
}
.AnalyzingFile-module_analyzingFileWrapper__j0Ltf .AnalyzingFile-module_fileBox__ywFIh p {
  color: #585858;
  font-size: 16px;
  line-height: 19px;
}
.OpenOptions-module_optionsContainer__-WqT8 {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
  padding: 12px;
  width: 430px;
}
.OpenOptions-module_dropContainer__PL6Tg {
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(103, 104, 132, 0.18);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 134px;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  width: 128px;
}
.OpenOptions-module_dropContainer__PL6Tg div {
  margin: 0;
  padding: 0;
}
.OpenOptions-module_dropContainer__PL6Tg p {
  font-family:
    Inter,
    FreeSans,
    Arimo,
    Droid Sans,
    Helvetica,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 10px;
  line-height: 12.1px;
  margin: 0;
  text-align: center;
}
.OpenOptions-module_dropContainer__PL6Tg .OpenOptions-module_buttonLabelWrapper__h2YsI {
  display: flex;
  flex-direction: column;
  height: 24px;
  justify-content: center;
}
.OpenOptions-module_dropContainer__PL6Tg .OpenOptions-module_buttonLabel__LiY-- {
  color: #585858;
  font-weight: 500;
  text-transform: uppercase;
}
.OpenOptions-module_dropContainer__PL6Tg .OpenOptions-module_textLabelWrapper__Vx8NF {
  height: 12px;
}
.OpenOptions-module_dropContainer__PL6Tg .OpenOptions-module_textLabel__TlOfz {
  color: #333;
  font-weight: 400;
  opacity: 50%;
}
.OpenOptions-module_dropContainer__PL6Tg .OpenOptions-module_dropIconWrapper__ibBQx {
  height: 40px;
}
.FileDrop-module_isHovered__c-CDn {
  background-color: #f8feff;
}
.FileDrop-module_isDisabled__Ejuyq {
  cursor: auto;
}
.FileDrop-module_isDisabled__Ejuyq svg {
  filter: grayscale(1);
  opacity: 0.6;
}
.Tabs-module_tabs__PuztJ {
  width: 100%;
}
.Tabs-module_tabs__PuztJ a {
  background-color: #ddd;
  color: #333;
  cursor: pointer;
  display: inline-block;
  line-height: 14px;
  padding: 5px 12px;
  text-align: center;
}
.Tabs-module_tabs__PuztJ a:hover {
  background-color: #00838f;
  color: #fff;
}
.Tabs-module_tabs__PuztJ .Tabs-module_active__eeyNd {
  background-color: #005662;
  color: #fff;
}
.Tabs-module_tabs__PuztJ :first-child {
  border-radius: 4px 0 0 0;
}
.Tabs-module_tabs__PuztJ :last-child {
  border-radius: 0 4px 0 0;
}
.Tabs-module_tabs__PuztJ :only-child {
  border-radius: 4px 4px 0 0;
}
.Save-module_button-common-styles__mpLS8 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Save-module_scrollbar__XMf7I {
  scrollbar-width: thin;
}
.Save-module_scrollbar__XMf7I ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Save-module_scrollbar__XMf7I::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Save-module_scrollbar__XMf7I::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Save-module_scrollbar__XMf7I::-webkit-scrollbar-thumb:active,
.Save-module_scrollbar__XMf7I::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq {
  display: flex;
  flex-direction: column;
  height: 310px;
  overflow: hidden;
  width: 430px;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq form {
  align-content: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin: 12px 12px 10px;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq form > label {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: calc(50% - 6px);
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq form > label input {
  border: 1px solid #cad3dd !important;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq form > label input:hover {
  border: 1px solid #43b5c0 !important;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq form > label > span {
  margin-right: 8px;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_tabs__TOB-R {
  outline: none;
  padding-left: 12px;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_tabs__TOB-R[tabindex='0'] a:nth-child(2) {
  color: #ff4a4a;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_warnings__1PxJT {
  background-color: #eff2f5;
  box-sizing: border-box;
  flex-shrink: 0;
  max-height: 50px;
  min-height: 224px;
  overflow-x: auto;
  padding: 12px;
  scrollbar-width: thin;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_warnings__1PxJT ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_warnings__1PxJT::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_warnings__1PxJT::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Save-module_dialog__of-0M
  .Save-module_formContainer__v1pIq
  .Save-module_warnings__1PxJT::-webkit-scrollbar-thumb:active,
.Save-module_dialog__of-0M
  .Save-module_formContainer__v1pIq
  .Save-module_warnings__1PxJT::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Save-module_dialog__of-0M
  .Save-module_formContainer__v1pIq
  .Save-module_warnings__1PxJT
  .Save-module_warningsContainer__-3yVy {
  display: flex;
  padding-bottom: 12px;
}
.Save-module_dialog__of-0M
  .Save-module_formContainer__v1pIq
  .Save-module_warnings__1PxJT
  .Save-module_warningsContainer__-3yVy
  .Save-module_warningsArr__bKUFY {
  color: #333;
  font-size: 12px;
  line-height: 14px;
  white-space: pre-line;
  word-break: break-word;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewBackground__AbaUf {
  background-color: #eff2f5;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewArea__-0SLb {
  background-color: #eff2f5;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #333;
  cursor: text;
  display: block;
  font-size: 12px;
  height: 100%;
  min-height: 224px;
  min-width: 428px;
  outline: none;
  overflow-x: auto;
  padding: 12px;
  resize: none;
  scrollbar-width: thin;
  white-space: pre;
  width: 100%;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewArea__-0SLb ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewArea__-0SLb::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewArea__-0SLb::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Save-module_dialog__of-0M
  .Save-module_formContainer__v1pIq
  .Save-module_previewArea__-0SLb::-webkit-scrollbar-thumb:active,
.Save-module_dialog__of-0M
  .Save-module_formContainer__v1pIq
  .Save-module_previewArea__-0SLb::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewArea__-0SLb:active,
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewArea__-0SLb:focus,
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewArea__-0SLb:hover {
  border: none;
  box-shadow: none;
  outline: none;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_loadingCirclesContainer__COUKN {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 20%;
  width: 100%;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_imageContainer__gFELk {
  align-items: center;
  border-bottom: 1px solid #e1e5ea;
  border-top: 1px solid #e1e5ea;
  display: flex;
  flex-direction: column;
  height: 220px;
  justify-content: center;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_imageContainer__gFELk img {
  background-color: #fff;
  max-height: 220px;
  max-width: 428px;
}
.Save-module_dialog__of-0M .Save-module_formContainer__v1pIq .Save-module_previewMessage__CZfZB {
  margin: auto;
  text-align: center;
}
.Save-module_dialog__of-0M .Save-module_ok__SgeCM {
  align-items: center;
  background-color: #167782;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Save-module_dialog__of-0M .Save-module_ok__SgeCM:active,
.Save-module_dialog__of-0M .Save-module_ok__SgeCM:hover {
  background-color: #4fb3bf;
}
.Save-module_dialog__of-0M .Save-module_ok__SgeCM:disabled {
  background-color: #e1e5ea;
  color: #333;
}
.Save-module_dialog__of-0M .Save-module_cancel__SMkEV,
.Save-module_dialog__of-0M .Save-module_saveTmpl__O-d0T {
  align-items: center;
  background-color: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Save-module_dialog__of-0M .Save-module_cancel__SMkEV:active,
.Save-module_dialog__of-0M .Save-module_cancel__SMkEV:hover,
.Save-module_dialog__of-0M .Save-module_saveTmpl__O-d0T:active,
.Save-module_dialog__of-0M .Save-module_saveTmpl__O-d0T:hover {
  border: 1px solid #333;
  color: #333;
}
.Save-module_dialog__of-0M .Save-module_cancel__SMkEV:disabled,
.Save-module_dialog__of-0M .Save-module_saveTmpl__O-d0T:disabled {
  border: 1px solid rgba(88, 88, 88, 0.7);
  color: rgba(88, 88, 88, 0.7);
}
.Save-module_dialog__of-0M .Save-module_saveTmpl__O-d0T {
  word-wrap: normal;
  margin-right: auto;
  padding: 2px 8px;
}
.enhancedStereo-module_enhancedStereo__8FLSY {
  width: 260px;
}
.enhancedStereo-module_enhancedStereo__8FLSY fieldset {
  border: none;
  display: flex;
  flex-direction: column;
}
.enhancedStereo-module_enhancedStereo__8FLSY fieldset label {
  margin-top: 12px;
}
.enhancedStereo-module_enhancedStereo__8FLSY .enhancedStereo-module_stereoLabelItem__lEf-0 label {
  align-items: center;
  display: flex;
  margin: 2px;
  min-height: 2.4em;
}
.enhancedStereo-module_enhancedStereo__8FLSY .enhancedStereo-module_labelGroupSelect__K7TYC {
  margin-left: 5px;
  margin-right: 5px;
}
.labelEdit-module_button-common-styles__S5XMA {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.labelEdit-module_scrollbar__GFcQm {
  scrollbar-width: thin;
}
.labelEdit-module_scrollbar__GFcQm ::-webkit-scrollbar-track {
  background-color: transparent;
}
.labelEdit-module_scrollbar__GFcQm::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.labelEdit-module_scrollbar__GFcQm::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.labelEdit-module_scrollbar__GFcQm::-webkit-scrollbar-thumb:active,
.labelEdit-module_scrollbar__GFcQm::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.labelEdit-module_labelEdit__9LnIH {
  display: flex;
  white-space: nowrap;
}
.labelEdit-module_labelEdit__9LnIH > div {
  overflow: hidden;
  padding: 0 12px;
}
.labelEdit-module_labelEdit__9LnIH div > form {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 12px;
}
.labelEdit-module_labelEdit__9LnIH .labelEdit-module_labelEditInputField__tb-J4 label {
  text-align: center;
}
.labelEdit-module_labelEdit__9LnIH .labelEdit-module_labelEditInputField__tb-J4 input {
  margin-left: 8px;
  min-width: 140px;
}
.AtomInfo-module_button-common-styles__x3ucv {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.AtomInfo-module_scrollbar__8GiyC {
  scrollbar-width: thin;
}
.AtomInfo-module_scrollbar__8GiyC ::-webkit-scrollbar-track {
  background-color: transparent;
}
.AtomInfo-module_scrollbar__8GiyC::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.AtomInfo-module_scrollbar__8GiyC::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.AtomInfo-module_scrollbar__8GiyC::-webkit-scrollbar-thumb:active,
.AtomInfo-module_scrollbar__8GiyC::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.AtomInfo-module_ket_atom_info__GOuZ6 {
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.12);
  font-size: 10px;
  left: 18em;
  opacity: 1;
  padding: 0.75em;
  position: absolute;
  top: 2em;
  transition:
    opacity 1s,
    visibility 0s;
  width: 60px;
}
.AtomInfo-module_none__PRuzx {
  opacity: 0;
  transition:
    opacity 2s,
    visibility 2s;
}
.ElementsTable-module_button-common-styles__-6eHN {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.ElementsTable-module_scrollbar__0R-HA {
  scrollbar-width: thin;
}
.ElementsTable-module_scrollbar__0R-HA ::-webkit-scrollbar-track {
  background-color: transparent;
}
.ElementsTable-module_scrollbar__0R-HA::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.ElementsTable-module_scrollbar__0R-HA::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.ElementsTable-module_scrollbar__0R-HA::-webkit-scrollbar-thumb:active,
.ElementsTable-module_scrollbar__0R-HA::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.ElementsTable-module_table__iNPVY {
  border-spacing: 3px;
  position: relative;
}
.ElementsTable-module_table__iNPVY td,
.ElementsTable-module_table__iNPVY th {
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_main_row__csN5a,
.ElementsTable-module_table__iNPVY th {
  font-size: 8px;
  font-weight: 400;
  opacity: 0.6;
  padding: 0.3em 0.8em;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_outiner_row__fzfGL {
  text-align: right;
  vertical-align: top;
}
.ElementsTable-module_table__iNPVY tr:nth-of-type(8) {
  border-bottom: 1em solid transparent;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_noble__65B92 {
  background: #c2ffff;
  border: 2px solid #c2ffff;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_alkali__sM9z9 {
  background: #ffa2a5;
  border: 2px solid #ffa2a5;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_alkaline-earth__t2h2s {
  background: #ffe0b5;
  border: 2px solid #ffe0b5;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_metalloid__Ui4Y8 {
  background: #e2e2be;
  border: 2px solid #e2e2be;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_other-nonmetal__mbkMN {
  background: #e8ff94;
  border: 2px solid #e8ff94;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_post-transition__k4C-G {
  background: #c5c5c5;
  border: 2px solid #c5c5c5;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_transition__GCnID {
  background: #ffd2d4;
  border: 2px solid #ffd2d4;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_lanthanide__wVoLa {
  background: #f4c8f4;
  border: 2px solid #f4c8f4;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_actinide__bVCBg {
  background: #fd98cb;
  border: 2px solid #fd98cb;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_halogen__W6EjM {
  background: #fffea6;
  border: 2px solid #fffea6;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_unknown-props__d9rq4 {
  background: #e8e8e8;
}
.ElementsTable-module_table__iNPVY .ElementsTable-module_unknown-state__lRBCp:after {
  color: #222;
}
.ElementsTable-module_button__xCaL4 {
  background-color: #fff;
  background-image: none;
  border: 0;
  border-radius: 2px;
  color: #333;
  color: #585858;
  display: inline-block;
  display: block;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  line-height: 2;
  margin: 0;
  padding: 0.15em;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 24px;
}
.ElementsTable-module_button__xCaL4:active {
  background-image: none;
  outline: 0;
}
.ElementsTable-module_button__xCaL4[disabled],
fieldset[disabled] .ElementsTable-module_button__xCaL4 {
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
}
.ElementsTable-module_button__xCaL4 > input[type='file'] {
  left: -9999px;
  position: absolute;
}
.ElementsTable-module_button__xCaL4 + button,
.ElementsTable-module_button__xCaL4 + input[type='reset'],
.ElementsTable-module_button__xCaL4 + input[type='submit'] {
  margin-left: 0.3em;
}
.ElementsTable-module_button__xCaL4:after {
  color: #585858;
  content: attr(value);
  font-size: 0.5em;
  line-height: 1;
  opacity: 0.6;
  position: absolute;
  right: 0.3em;
  top: 0.3em;
  vertical-align: top;
}
.ElementsTable-module_button__xCaL4:hover {
  background: #fff;
  z-index: 60;
}
.ElementsTable-module_button__xCaL4.ElementsTable-module_selected__B9LGf {
  background: #5e5e5e;
  border: none;
  color: #fff;
}
.ElementsTable-module_button__xCaL4.ElementsTable-module_selected__B9LGf > span {
  color: #fff;
}
.ElementsTable-module_button__xCaL4.ElementsTable-module_selected__B9LGf > svg {
  fill: #fff;
  stroke: #fff;
}
.ElementsTable-module_button__xCaL4.ElementsTable-module_selected__B9LGf path {
  stroke: #fff;
}
.ElementsTable-module_button__xCaL4.ElementsTable-module_selected__B9LGf:after {
  color: #fff;
  opacity: 1;
}
.BoxWithLines-module_horizintalNodeLines__QO-Su {
  display: flex;
  flex: 0 0 20px;
  flex-direction: column;
  margin-top: 2px;
  padding-left: 14px;
  width: calc(50% + 6px);
}
.BoxWithLines-module_horizintalNodeLines__QO-Su .BoxWithLines-module_upperLines__8pL-R {
  border-bottom: 1px solid #cad3dd;
  border-left: 1px solid #cad3dd;
  height: 5px;
}
.BoxWithLines-module_horizintalNodeLines__QO-Su .BoxWithLines-module_lowerLine__tBZJd {
  border-left: 1px solid #cad3dd;
  border-right: 1px solid #cad3dd;
  height: 4px;
  margin-bottom: -10px;
  padding-bottom: 2px;
}
.BoxWithLines-module_verticalNodeLines__wBgwS {
  display: flex;
  flex: 0 0 20px;
  flex-direction: column;
  margin-right: 1px;
  margin-top: 4px;
  width: 5px;
}
.BoxWithLines-module_verticalNodeLines__wBgwS .BoxWithLines-module_upperLines__8pL-R {
  border-bottom: 1px solid #cad3dd;
  border-left: 1px solid #cad3dd;
  height: 14px;
}
.BoxWithLines-module_verticalNodeLines__wBgwS .BoxWithLines-module_lowerLine__tBZJd {
  border-bottom: 1px solid #cad3dd;
  border-left: 1px solid #cad3dd;
  height: 96px;
  margin-bottom: -16px;
  padding-bottom: 4px;
}
.GenSet-module_button-common-styles__0Ro-U {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.GenSet-module_scrollbar__Ev8Uz {
  scrollbar-width: thin;
}
.GenSet-module_scrollbar__Ev8Uz ::-webkit-scrollbar-track {
  background-color: transparent;
}
.GenSet-module_scrollbar__Ev8Uz::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.GenSet-module_scrollbar__Ev8Uz::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.GenSet-module_scrollbar__Ev8Uz::-webkit-scrollbar-thumb:active,
.GenSet-module_scrollbar__Ev8Uz::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.GenSet-module_genGroupLegendBox__CSTQr {
  position: absolute;
  top: -14px;
  width: 100%;
}
.GenSet-module_genGroupLegendBox__CSTQr > legend {
  font-size: 10px !important;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
}
.GenSet-module_legendBox__0zIAk {
  line-height: unset;
  position: relative;
}
.GenSet-module_legendBox__0zIAk > legend {
  font-size: 10px !important;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
}
.GenSet-module_SpecialNodes__oFKp3 {
  -moz-column-gap: 4px;
  column-gap: 4px;
  display: grid;
  grid-template-columns: repeat(4, 26px) 36px;
  grid-template-rows: 24px;
}
.GenSet-module_AtomGenerics__MoQCf,
.GenSet-module_GroupGenerics__pFPtX {
  -moz-column-gap: 4px;
  column-gap: 4px;
  display: grid;
  grid-template-columns: 26px 36px;
  grid-template-rows: 24px;
}
.GenSet-module_AcyclicCarbo__nowGn,
.GenSet-module_AcyclicHetero__hnr4G,
.GenSet-module_Acyclic__R97MM,
.GenSet-module_CyclicCarbo__Aanb0,
.GenSet-module_CyclicHetero__bbOnT,
.GenSet-module_Cyclic__VJRtt {
  -moz-column-gap: 4px;
  column-gap: 4px;
  display: grid;
  grid-template-columns: repeat(2, 36px);
  grid-template-rows: 24px;
}
.ButtonGenSet-module_button-common-styles__3bEj7 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.ButtonGenSet-module_scrollbar__CKIcM {
  scrollbar-width: thin;
}
.ButtonGenSet-module_scrollbar__CKIcM ::-webkit-scrollbar-track {
  background-color: transparent;
}
.ButtonGenSet-module_scrollbar__CKIcM::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.ButtonGenSet-module_scrollbar__CKIcM::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.ButtonGenSet-module_scrollbar__CKIcM::-webkit-scrollbar-thumb:active,
.ButtonGenSet-module_scrollbar__CKIcM::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.ButtonGenSet-module_button__hBoCQ {
  background: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  font-size: 14px;
  height: 100%;
  line-height: 12px;
  padding: 0;
  vertical-align: middle;
  width: 100%;
}
.ButtonGenSet-module_button__hBoCQ:hover {
  border: 1px solid #167782;
  color: #585858;
}
.ButtonGenSet-module_button__hBoCQ.ButtonGenSet-module_selected__XAfYj,
.ButtonGenSet-module_button__hBoCQ:focus {
  background-color: #167782;
  border: 1px solid #167782;
  color: #fff;
}
.ButtonGenSet-module_button__hBoCQ:disabled {
  border: 1px solid #b4b9d6;
  color: #b4b9d6;
}
.GenGroup-module_fieldFlexWrapper__mBHRt {
  display: flex;
  margin-top: 12px;
}
.GenGroup-module_buttonContainer__7qTti {
  display: grid;
  gap: 8px 12px;
  grid-template-columns: 1fr 1fr;
}
.GenGroup-module_genButtonContainer__6Bwe1 {
  display: grid;
  gap: 22px 12px;
  grid-template-columns: 1fr 1fr;
}
.GenGroup-module_subGroup__afiEU {
  border: none;
  padding: 0;
  position: relative;
}
.GenGroup-module_legendMain__h-Wl3 {
  color: #333 !important;
  font-weight: 700 !important;
}
.GenGroup-module_legend__JWafm {
  color: rgba(51, 51, 51, 0.8) !important;
  font-weight: 700 !important;
}
.GenGroup-module_fieldset__JehYt {
  border: none;
  padding: 4px 0 0;
}
.GenericGroups-module_button-common-styles__nYUJe {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.GenericGroups-module_scrollbar__5iavS {
  scrollbar-width: thin;
}
.GenericGroups-module_scrollbar__5iavS ::-webkit-scrollbar-track {
  background-color: transparent;
}
.GenericGroups-module_scrollbar__5iavS::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.GenericGroups-module_scrollbar__5iavS::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.GenericGroups-module_scrollbar__5iavS::-webkit-scrollbar-thumb:active,
.GenericGroups-module_scrollbar__5iavS::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.GenericGroups-module_genericGroups__z4EaG {
  margin-top: 9px;
  overflow-y: auto;
  padding: 0 12px 12px;
  scrollbar-width: thin;
}
.GenericGroups-module_genericGroups__z4EaG::-webkit-scrollbar {
  background-color: transparent;
}
.GenericGroups-module_genericGroups__z4EaG:first-child {
  margin-top: 0;
}
.GenericGroups-module_genericGroups__z4EaG > fieldset {
  border: none;
  margin: 1em;
  padding: 0;
}
.GenericGroups-module_genericGroups__z4EaG ::-webkit-scrollbar-track {
  background-color: transparent;
}
.GenericGroups-module_genericGroups__z4EaG::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.GenericGroups-module_genericGroups__z4EaG::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.GenericGroups-module_genericGroups__z4EaG::-webkit-scrollbar-thumb:active,
.GenericGroups-module_genericGroups__z4EaG::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.GenericGroups-module_genericGroups__z4EaG .GenericGroups-module_groupGenerics__6UpH6,
.GenericGroups-module_genericGroups__z4EaG .GenericGroups-module_topGroupsContainer__bMeoG {
  grid-column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.GenericGroups-module_genericGroups__z4EaG .GenericGroups-module_subgroupContainer__xULxX {
  display: grid;
  grid-template-columns: 6px 1fr;
}
.TypeChoice-module_fieldset__yJqT7 {
  border: none;
  display: flex;
  gap: 12px;
  padding: 0;
  width: 100%;
}
.TypeChoice-module_fieldset__yJqT7 > label {
  align-items: center;
  display: flex;
  font-size: 12px;
}
.PeriodTable-module_button-common-styles__8JrOW {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.PeriodTable-module_scrollbar__iG-vx {
  scrollbar-width: thin;
}
.PeriodTable-module_scrollbar__iG-vx ::-webkit-scrollbar-track {
  background-color: transparent;
}
.PeriodTable-module_scrollbar__iG-vx::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.PeriodTable-module_scrollbar__iG-vx::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.PeriodTable-module_scrollbar__iG-vx::-webkit-scrollbar-thumb:active,
.PeriodTable-module_scrollbar__iG-vx::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.PeriodTable-module_elementsTable__Vcz12 {
  background-color: #fff;
  height: 368px;
  width: 520px;
}
.PeriodTable-module_elementsTable__Vcz12 > div {
  margin: 0;
  overflow: hidden;
  padding-left: 8px;
  padding-top: 12px;
}
.PeriodTable-module_elementsTable__Vcz12 > header {
  border-bottom: 1px solid #e1e5ea;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_dialogHeader__-Wgw4 {
  align-items: center;
  display: flex;
  font-style: normal;
  font-weight: 500;
  gap: 4px;
  padding-left: 2px;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_dialogHeader__-Wgw4 > span {
  line-height: 16px;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_dialogHeader__-Wgw4 > svg {
  height: 16px;
  width: 16px;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_tabs__q-2GL {
  border-bottom: 1px solid #e1e5ea;
  outline: none;
  padding-left: 15px;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_tabsContent__5rij5 {
  height: 31em;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_contentGeneral__-XKPp {
  padding-left: 15px;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_periodTable__l-Uqx {
  position: fixed;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_periodTable__l-Uqx > * {
  line-height: normal;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_addAtom__ehSSN {
  background: #005662;
  border: none;
  border-radius: 2px;
  border-radius: 6px;
  color: #fff;
  float: right;
  position: relative;
  z-index: 10;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_addAtom__ehSSN:hover {
  background: #00838f;
  color: #fff;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_addAtom__ehSSN:disabled {
  background: rgba(0, 131, 143, 0.4);
  color: #fff;
}
.PeriodTable-module_elementsTable__Vcz12 .PeriodTable-module_addAtom__ehSSN:focus {
  background: #4fb3bf;
}
.ExtendedTable-module_extendedTable__2-hjV {
  width: 390px;
}
.RemoveFG-module_window__6PHH4 {
  background: #f3f3f3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-family:
    Inter,
    FreeSans,
    Arimo,
    Droid Sans,
    Helvetica,
    Helvetica Neue,
    Arial,
    sans-serif;
  width: 260px;
}
.RemoveFG-module_window__6PHH4 > * {
  margin: 10px 15px;
}
.RemoveFG-module_question__J8Qzx {
  color: #585858;
  text-align: left;
}
.RemoveFG-module_header__1hBAs {
  align-items: center;
  display: flex;
  margin-top: 15px;
  text-transform: uppercase;
}
.RemoveFG-module_footer__8wImu {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.RemoveFG-module_buttonCancel__xCaJt,
.RemoveFG-module_buttonOk__zR5x4 {
  border: none;
  border-radius: 2px;
  height: 1.8em;
  width: content-box;
}
.RemoveFG-module_buttonOk__zR5x4 {
  background: #005662;
  color: #fff;
}
.RemoveFG-module_buttonOk__zR5x4:hover {
  background: #00838f;
}
.RemoveFG-module_buttonCancel__xCaJt {
  background: transparent;
  border: 1px solid #717171;
  color: #717171;
  margin-right: 15px;
}
.RemoveFG-module_buttonCancel__xCaJt:hover {
  border-color: #333;
  color: #333;
}
.rgroup-module_button-common-styles__O66iR {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.rgroup-module_scrollbar__GmU8o {
  scrollbar-width: thin;
}
.rgroup-module_scrollbar__GmU8o ::-webkit-scrollbar-track {
  background-color: transparent;
}
.rgroup-module_scrollbar__GmU8o::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.rgroup-module_scrollbar__GmU8o::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.rgroup-module_scrollbar__GmU8o::-webkit-scrollbar-thumb:active,
.rgroup-module_scrollbar__GmU8o::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.rgroup-module_rgroup__Pm-uA {
  width: 260px;
}
.rgroup-module_rgroup__Pm-uA > div {
  background-color: #eff2f5;
  margin: 0;
  padding: 12px 11px;
}
.rgroup-module_rgroup__Pm-uA ul {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  display: grid;
  grid-template-columns: repeat(5, 41px);
  grid-template-rows: repeat(3, 29px 33px) 29px;
  overflow: hidden;
}
.rgroup-module_rgroup__Pm-uA form button {
  background-color: #fff;
  background-image: none;
  background: #fff;
  border: none;
  border-radius: 2px;
  color: #333;
  color: #585858;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 29px;
  margin: 0;
  padding: 6px 4px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 100%;
}
.rgroup-module_rgroup__Pm-uA form button:active {
  background-image: none;
  outline: 0;
}
.rgroup-module_rgroup__Pm-uA form button[disabled],
fieldset[disabled] .rgroup-module_rgroup__Pm-uA form button {
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
}
.rgroup-module_rgroup__Pm-uA form button > input[type='file'] {
  left: -9999px;
  position: absolute;
}
.rgroup-module_rgroup__Pm-uA form button + button,
.rgroup-module_rgroup__Pm-uA form button + input[type='reset'],
.rgroup-module_rgroup__Pm-uA form button + input[type='submit'] {
  margin-left: 0.3em;
}
.rgroup-module_rgroup__Pm-uA form button:first-letter {
  font-weight: 400;
}
.rgroup-module_rgroup__Pm-uA form button:hover {
  color: #005662;
}
.rgroup-module_rgroup__Pm-uA form button.rgroup-module_selected__hP5cM,
.rgroup-module_rgroup__Pm-uA form button:active {
  background: #167782;
  color: #fff;
}
.sgroup-module_button-common-styles__MAeEP {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.sgroup-module_scrollbar__T3fpf {
  scrollbar-width: thin;
}
.sgroup-module_scrollbar__T3fpf ::-webkit-scrollbar-track {
  background-color: transparent;
}
.sgroup-module_scrollbar__T3fpf::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.sgroup-module_scrollbar__T3fpf::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.sgroup-module_scrollbar__T3fpf::-webkit-scrollbar-thumb:active,
.sgroup-module_scrollbar__T3fpf::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.sgroup-module_sgroup__Kh2Xj {
  background-color: #fff;
  border-radius: 6px;
  width: 260px;
}
.sgroup-module_sgroup__Kh2Xj label {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.sgroup-module_sgroup__Kh2Xj label ul {
  width: inherit;
}
.sgroup-module_sgroup__Kh2Xj label .MuiOutlinedInput-root,
.sgroup-module_sgroup__Kh2Xj label input[type='text'] {
  margin-top: 4px;
  max-width: 236px;
}
.sgroup-module_sgroup__Kh2Xj fieldset {
  border: 0;
  padding: 0;
}
.sgroup-module_sgroup__Kh2Xj fieldset[class='sdata'] fieldset {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
}
.sgroup-module_sgroup__Kh2Xj fieldset[class='sdata'] fieldset label {
  display: flex;
  flex-direction: row;
  line-height: 16px;
}
.sgroup-module_sgroup__Kh2Xj fieldset[class='sdata'] fieldset label > span {
  margin-right: 3px !important;
}
.sgroup-module_sgroup__Kh2Xj form {
  margin: 12px 12px 4px;
}
.sgroup-module_sgroup__Kh2Xj form label {
  font-size: 12px;
  text-align: left;
}
.sgroup-module_sgroup__Kh2Xj form label > span > div {
  width: 100%;
}
.sgroup-module_sgroup__Kh2Xj form label fieldset label {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 4px !important;
}
.sgroup-module_sgroup__Kh2Xj form label fieldset span {
  margin-right: 4px !important;
}
.sgroup-module_sgroup__Kh2Xj form select[name='fieldValue'],
.sgroup-module_sgroup__Kh2Xj form textarea {
  display: block;
  overflow: auto;
}
.sgroup-module_sgroup__Kh2Xj form input[type='text'],
.sgroup-module_sgroup__Kh2Xj form select[name='fieldName'] {
  color: #333;
  display: block;
  margin-bottom: 0;
  width: 100%;
}
.sgroup-module_sgroup__Kh2Xj form textarea {
  margin-top: 4px;
  max-height: 240px;
  min-height: 56px;
  resize: vertical;
  scrollbar-width: thin;
  width: 100%;
}
.sgroup-module_sgroup__Kh2Xj form textarea ::-webkit-scrollbar-track {
  background-color: transparent;
}
.sgroup-module_sgroup__Kh2Xj form textarea::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.sgroup-module_sgroup__Kh2Xj form textarea::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.sgroup-module_sgroup__Kh2Xj form textarea::-webkit-scrollbar-thumb:active,
.sgroup-module_sgroup__Kh2Xj form textarea::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.sgroup-module_sgroup__Kh2Xj form textarea::-webkit-scrollbar {
  background-color: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  width: 4px;
}
.template-lib-module_button-common-styles__9adxB {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.template-lib-module_scrollbar__ZFwCC {
  scrollbar-width: thin;
}
.template-lib-module_scrollbar__ZFwCC ::-webkit-scrollbar-track {
  background-color: transparent;
}
.template-lib-module_scrollbar__ZFwCC::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.template-lib-module_scrollbar__ZFwCC::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.template-lib-module_scrollbar__ZFwCC::-webkit-scrollbar-thumb:active,
.template-lib-module_scrollbar__ZFwCC::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.template-lib-module_warning__UqgEG {
  color: #ff4a4a;
}
.template-lib-module_warning__UqgEG:before {
  content: '⚠ ';
}
.template-lib-module_dialogHeader__-AmwE {
  align-items: center;
  display: flex;
  gap: 4px;
  padding-left: 2px;
}
.template-lib-module_dialogHeader__-AmwE > span {
  line-height: 16px;
}
.template-lib-module_dialogHeader__-AmwE > svg {
  height: 18px;
  width: 18px;
}
.template-lib-module_button__LKf1c {
  min-width: 70px !important;
}
.template-lib-module_dialog_body__QtL0J {
  background-color: #fff;
  color: #333;
  width: 452px;
}
.template-lib-module_dialog_body__QtL0J > div {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.template-lib-module_dialog_body__QtL0J > footer {
  box-shadow: 0 -6px 6px 0 rgba(1, 1, 50, 0.1);
  min-height: 24px;
  z-index: 10;
}
.template-lib-module_dialog_body__QtL0J > footer > input {
  min-width: 110px !important;
}
.template-lib-module_dialog_body__QtL0J > footer > span {
  line-height: 24px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_inputContainer__dxoHA {
  position: relative;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_inputContainer__dxoHA input[type='search'] {
  background-color: #eff2f5;
  height: 28px;
  margin: 0 14px 16px;
  padding-left: 35px;
  width: 424px;
}
.template-lib-module_dialog_body__QtL0J
  .template-lib-module_inputContainer__dxoHA
  input[type='search']::-moz-placeholder {
  font-weight: 400;
  opacity: 90%;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_inputContainer__dxoHA input[type='search']::placeholder {
  font-weight: 400;
  opacity: 90%;
}
.template-lib-module_dialog_body__QtL0J
  .template-lib-module_inputContainer__dxoHA
  .template-lib-module_searchIcon__o7cAy {
  color: #167782;
  height: 28px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 28px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_accordionSummary__2hIop {
  flex-direction: row-reverse;
  font-size: 14px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_groupIcon__LBIRH {
  margin-left: 15px;
  margin-right: 8px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_expandIcon__Fb1EU {
  width: 20px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_tabsContent__1QMnN {
  background-color: #eff2f5;
  height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_tabsContent__1QMnN ::-webkit-scrollbar-track {
  background-color: transparent;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_tabsContent__1QMnN::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_tabsContent__1QMnN::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_tabsContent__1QMnN::-webkit-scrollbar-thumb:active,
.template-lib-module_dialog_body__QtL0J .template-lib-module_tabsContent__1QMnN::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_resultsContainer__KnPBZ {
  background-color: #eff2f5;
  padding: 12px 14px 4px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_saveButton__8PE6t {
  align-items: center;
  background-color: #fff;
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_saveButton__8PE6t:active,
.template-lib-module_dialog_body__QtL0J .template-lib-module_saveButton__8PE6t:hover {
  border: 1px solid #333;
  color: #333;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_saveButton__8PE6t:disabled {
  border: 1px solid rgba(88, 88, 88, 0.7);
  color: rgba(88, 88, 88, 0.7);
}
.template-lib-module_dialog_body__QtL0J .MuiTabs-indicator {
  background-color: #167782;
}
.template-lib-module_dialog_body__QtL0J .MuiTabs-flexContainer {
  background-color: #eff2f5;
  padding-left: 14px;
}
.template-lib-module_dialog_body__QtL0J .MuiAccordionSummary-content {
  align-items: end;
}
.template-lib-module_dialog_body__QtL0J .MuiAccordionDetails-root {
  background-color: #eff2f5;
  box-shadow: inset 0 -2px 8px 0 rgba(1, 1, 50, 0.1);
  padding: 12px 14px 4px;
  width: 424px;
}
.template-lib-module_dialog_body__QtL0J .MuiPaper-root.MuiAccordion-root {
  box-shadow: none;
}
.template-lib-module_dialog_body__QtL0J .MuiPaper-root.MuiAccordion-root.Mui-expanded:before,
.template-lib-module_dialog_body__QtL0J .MuiPaper-root.MuiAccordion-root:before {
  opacity: 0;
}
.template-lib-module_dialog_body__QtL0J .MuiPaper-root:not(:last-child):after {
  background-color: #b4b9d6;
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  margin: 0 auto;
  opacity: 1;
  position: absolute;
  right: 0;
  width: 424px;
}
.template-lib-module_dialog_body__QtL0J .MuiPaper-root.Mui-expanded:after {
  opacity: 0;
}
.template-lib-module_dialog_body__QtL0J .MuiButtonBase-root.MuiTab-root,
.template-lib-module_dialog_body__QtL0J .MuiTabs-root {
  height: 32px;
  min-height: 32px;
}
.template-lib-module_dialog_body__QtL0J .template-lib-module_tabs__YkpEG {
  box-shadow: 0 6px 6px 0 rgba(1, 1, 50, 0.1);
  z-index: 10;
}
.template-lib-module_dialog_body__QtL0J .MuiButtonBase-root.MuiTab-root {
  color: rgba(0, 0, 0, 0.7);
  line-height: 12px;
  padding: 12px 10px 8px;
  text-transform: none;
}
.template-lib-module_dialog_body__QtL0J .MuiTab-root.Mui-selected {
  color: #000;
  font-weight: 600;
}
.template-lib-module_dialog_body__QtL0J .MuiPaper-root.MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.template-lib-module_dialog_body__QtL0J .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded,
.template-lib-module_dialog_body__QtL0J .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
  height: 40px;
  min-height: 40px;
  padding-left: 12px;
}
.TemplateTable-module_button-common-styles__ZYUa5 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.TemplateTable-module_scrollbar__aSGhu {
  scrollbar-width: thin;
}
.TemplateTable-module_scrollbar__aSGhu ::-webkit-scrollbar-track {
  background-color: transparent;
}
.TemplateTable-module_scrollbar__aSGhu::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.TemplateTable-module_scrollbar__aSGhu::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.TemplateTable-module_scrollbar__aSGhu::-webkit-scrollbar-thumb:active,
.TemplateTable-module_scrollbar__aSGhu::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.TemplateTable-module_tableContent__dnVKg {
  grid-gap: 0 8px;
  display: grid;
  grid-template-columns: repeat(4, 100px);
}
.TemplateTable-module_structTitle__2XUWI {
  border: 1px solid transparent;
  border-radius: 6px;
  margin: 6px 0 12px;
  max-height: 30px;
  max-width: 100px;
  overflow: hidden;
  padding: 0 7px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.TemplateTable-module_twoRowsTitleTable__ZYwHq {
  grid-auto-rows: 147px;
}
.TemplateTable-module_oneRowTitleTable__N8Ibd {
  grid-auto-rows: 134px;
}
.TemplateTable-module_oneRowTitleTable__N8Ibd .TemplateTable-module_structTitle__2XUWI {
  max-height: 16px;
}
.TemplateTable-module_struct__lOLBI {
  background-color: #fff;
  border: 1px solid #b4b9d6;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100px;
  width: 100px;
}
.TemplateTable-module_struct__lOLBI:hover {
  background: #f9fafb;
}
.TemplateTable-module_struct__lOLBI svg {
  height: 100px;
  left: -2px;
  top: -1px;
  width: 100px;
}
.TemplateTable-module_selectedTitle__J-cte {
  background-color: hsla(0, 0%, 100%, 0.7);
}
.TemplateTable-module_td__6LIhx {
  cursor: default;
  position: relative;
}
.TemplateTable-module_td__6LIhx.TemplateTable-module_selected__qZEF9 .TemplateTable-module_struct__lOLBI {
  background-color: rgba(22, 119, 130, 0.05);
  border: 2px solid #167782;
}
.TemplateTable-module_button__dpOF0 {
  background-color: #fff;
  background-color: transparent;
  background-image: none;
  border: none;
  border-radius: 2px;
  border-radius: 0;
  color: #333;
  color: #636363;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin: 0;
  padding: 0;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 18px;
}
.TemplateTable-module_button__dpOF0:active {
  background-image: none;
  outline: 0;
}
.TemplateTable-module_button__dpOF0[disabled],
fieldset[disabled] .TemplateTable-module_button__dpOF0 {
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
}
.TemplateTable-module_button__dpOF0 > input[type='file'] {
  left: -9999px;
  position: absolute;
}
.TemplateTable-module_button__dpOF0 + button,
.TemplateTable-module_button__dpOF0 + input[type='reset'],
.TemplateTable-module_button__dpOF0 + input[type='submit'] {
  margin-left: 0.3em;
}
.TemplateTable-module_button__dpOF0 > svg {
  height: inherit;
  width: inherit;
}
.TemplateTable-module_button__dpOF0:active path,
.TemplateTable-module_button__dpOF0:hover path {
  fill: #00838f;
}
.TemplateTable-module_deleteButton__KfBpW {
  position: absolute;
  right: 5px;
  top: 5px;
}
.TemplateTable-module_editButton__A0BoE {
  position: absolute;
  right: 5px;
  top: 78px;
}
div.TemplateTable-module_structure__rI84W {
  background-color: #fff;
  padding: 0;
}
.emptySearchResult-module_emptySearch__aUSew {
  align-items: center;
  color: #333;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  min-height: 240px;
}
.emptySearchResult-module_emptySearch__aUSew > svg {
  height: 84px;
  width: 84px;
}
.FontControl-module_button-common-styles__CMSGk {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.FontControl-module_scrollbar__Vq3kt {
  scrollbar-width: thin;
}
.FontControl-module_scrollbar__Vq3kt ::-webkit-scrollbar-track {
  background-color: transparent;
}
.FontControl-module_scrollbar__Vq3kt::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.FontControl-module_scrollbar__Vq3kt::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.FontControl-module_scrollbar__Vq3kt::-webkit-scrollbar-thumb:active,
.FontControl-module_scrollbar__Vq3kt::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.FontControl-module_fontSizeMenu__4RvmP {
  background: #fff;
  border: 1px solid #cad3dd;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  max-height: 155px;
  overflow-y: scroll;
  padding: 4px;
  position: absolute;
  right: 12px;
  scrollbar-width: thin;
  top: 74px;
  width: 58px;
  z-index: 10;
}
.FontControl-module_fontSizeMenu__4RvmP ::-webkit-scrollbar-track {
  background-color: transparent;
}
.FontControl-module_fontSizeMenu__4RvmP::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.FontControl-module_fontSizeMenu__4RvmP::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.FontControl-module_fontSizeMenu__4RvmP::-webkit-scrollbar-thumb:active,
.FontControl-module_fontSizeMenu__4RvmP::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.FontControl-module_fontSizeOption__eGz-q {
  font-size: 14px;
  line-height: 16px;
  padding: 4px;
}
.FontControl-module_fontSizeOption__eGz-q:hover {
  background: #f3f3f3;
  border-radius: 4px;
  cursor: pointer;
}
.FontControl-module_fontBtn__zGgG3 {
  background-color: #fff;
  border: 1px solid #cad3dd;
  border-radius: 4px;
  font-size: 14px;
  height: 24px;
  line-height: 20px;
  text-align: left;
  width: 68px;
}
.FontControl-module_fontBtn__zGgG3:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.94 5.727 8 8.78l3.06-3.053.94.94-4 4-4-4 .94-.94Z' fill='%235B6077'/%3E%3C/svg%3E");
  background-size: contain;
  bottom: 20px;
  content: ' ';
  display: block;
  height: 22px;
  left: 18px;
  margin: auto;
  position: relative;
  width: 22px;
  z-index: 10;
}
.SpecialSymbolsList-module_window__4tD1N {
  background-color: #fff;
  border-radius: 0 6px 6px 6px;
  box-shadow: 2px 2px 6px 0 #cad3dd;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: visible;
  padding: 8px;
  position: absolute;
  right: 27px;
  width: 160px;
  z-index: 10;
}
.SpecialSymbolsList-module_button__uCp6O {
  background-color: transparent;
  border: none;
  height: 16px;
  margin: 2px;
  padding: initial;
  width: 16px;
}
.SpecialSymbolsList-module_button__uCp6O > svg {
  fill: #000;
}
.SpecialSymbolsList-module_button__uCp6O:hover > svg {
  fill: #167782;
}
.SpecialSymbolsButton-module_button-common-styles__OZY5u {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.SpecialSymbolsButton-module_scrollbar__BGjVm {
  scrollbar-width: thin;
}
.SpecialSymbolsButton-module_scrollbar__BGjVm ::-webkit-scrollbar-track {
  background-color: transparent;
}
.SpecialSymbolsButton-module_scrollbar__BGjVm::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.SpecialSymbolsButton-module_scrollbar__BGjVm::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.SpecialSymbolsButton-module_scrollbar__BGjVm::-webkit-scrollbar-thumb:active,
.SpecialSymbolsButton-module_scrollbar__BGjVm::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY,
.SpecialSymbolsButton-module_textButton__1gh-c {
  background-color: #fff;
  background-image: none;
  border: 0;
  border-radius: 2px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  height: 28px;
  margin: 0 !important;
  margin: 4px;
  padding: 0;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 28px;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY:active,
.SpecialSymbolsButton-module_textButton__1gh-c:active {
  background-image: none;
  outline: 0;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY[disabled],
.SpecialSymbolsButton-module_textButton__1gh-c[disabled],
fieldset[disabled] .SpecialSymbolsButton-module_activeTextButton__jbgHY,
fieldset[disabled] .SpecialSymbolsButton-module_textButton__1gh-c {
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY > input[type='file'],
.SpecialSymbolsButton-module_textButton__1gh-c > input[type='file'] {
  left: -9999px;
  position: absolute;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY + button,
.SpecialSymbolsButton-module_activeTextButton__jbgHY + input[type='reset'],
.SpecialSymbolsButton-module_activeTextButton__jbgHY + input[type='submit'],
.SpecialSymbolsButton-module_textButton__1gh-c + button,
.SpecialSymbolsButton-module_textButton__1gh-c + input[type='reset'],
.SpecialSymbolsButton-module_textButton__1gh-c + input[type='submit'] {
  margin-left: 0.3em;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY > svg,
.SpecialSymbolsButton-module_textButton__1gh-c > svg {
  font-size: 26px;
  height: 26px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 26px;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY {
  background-color: #167782;
  border-radius: 2px;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY > svg {
  fill: #fff;
  transition: 0.2s;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY:hover {
  background-color: #188794;
}
.SpecialSymbolsButton-module_textButton__1gh-c > svg {
  fill: #333;
  transition: 0.2s;
}
.SpecialSymbolsButton-module_textButton__1gh-c:hover {
  background-image: none;
}
.SpecialSymbolsButton-module_textButton__1gh-c:hover > svg {
  fill: #00838f;
}
.SpecialSymbolsButton-module_activeTextButton__jbgHY:after {
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  border-color: transparent #fff #fff transparent;
  border-style: solid;
  border-width: 2px;
  content: '';
  left: 21px;
  position: absolute;
  top: 21px;
}
.smallEditor .SpecialSymbolsButton-module_textButton__1gh-c {
  height: 28px;
  margin: 4px;
  width: 28px;
}
.smallEditor .SpecialSymbolsButton-module_textButton__1gh-c > svg {
  font-size: 20px;
  height: 20px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 20px;
}
.TextButton-module_button-common-styles__7F1Uf {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.TextButton-module_scrollbar__u11Yl {
  scrollbar-width: thin;
}
.TextButton-module_scrollbar__u11Yl ::-webkit-scrollbar-track {
  background-color: transparent;
}
.TextButton-module_scrollbar__u11Yl::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.TextButton-module_scrollbar__u11Yl::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.TextButton-module_scrollbar__u11Yl::-webkit-scrollbar-thumb:active,
.TextButton-module_scrollbar__u11Yl::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.TextButton-module_textButton__Ocny2 {
  background-color: #fff;
  background-image: none;
  border: 0;
  border-radius: 2px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  height: 28px;
  margin: 0 !important;
  margin: 4px;
  padding: 0;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 28px;
}
.TextButton-module_textButton__Ocny2:active {
  background-image: none;
  outline: 0;
}
.TextButton-module_textButton__Ocny2[disabled],
fieldset[disabled] .TextButton-module_textButton__Ocny2 {
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
}
.TextButton-module_textButton__Ocny2 > input[type='file'] {
  left: -9999px;
  position: absolute;
}
.TextButton-module_textButton__Ocny2 + button,
.TextButton-module_textButton__Ocny2 + input[type='reset'],
.TextButton-module_textButton__Ocny2 + input[type='submit'] {
  margin-left: 0.3em;
}
.TextButton-module_textButton__Ocny2 > svg {
  fill: #333;
  font-size: 26px;
  height: 26px;
  left: 1px;
  position: absolute;
  top: 1px;
  transition: 0.2s;
  width: 26px;
}
.TextButton-module_textButton__Ocny2:hover {
  background-image: none;
}
.TextButton-module_textButton__Ocny2:hover > svg {
  fill: #00838f;
}
.TextButton-module_textButton__Ocny2.TextButton-module_isActive__DQ-xh {
  background-color: #167782;
  border-radius: 2px;
}
.TextButton-module_textButton__Ocny2.TextButton-module_isActive__DQ-xh > svg {
  fill: #fff;
  transition: 0.2s;
}
.TextButton-module_textButton__Ocny2.TextButton-module_isActive__DQ-xh:hover {
  background-color: #188794;
}
.TextButton-module_textButton__Ocny2.TextButton-module_isActive__DQ-xh:hover > svg {
  fill: #fff;
  transition: 0.2s;
}
.smallEditor .TextButton-module_textButton__Ocny2 {
  height: 28px;
  margin: 4px;
  width: 28px;
}
.smallEditor .TextButton-module_textButton__Ocny2 > svg {
  font-size: 20px;
  height: 20px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 20px;
}
.Text-module_button-common-styles__dhz9O {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Text-module_scrollbar__0OhTV {
  scrollbar-width: thin;
}
.Text-module_scrollbar__0OhTV ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Text-module_scrollbar__0OhTV::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Text-module_scrollbar__0OhTV::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Text-module_scrollbar__0OhTV::-webkit-scrollbar-thumb:active,
.Text-module_scrollbar__0OhTV::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Text-module_textEditor__EFR54 {
  display: flex;
  flex-direction: column;
  width: 340px;
}
.Text-module_textEditor__EFR54 .Text-module_controlPanel__agLDc {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 28px;
  justify-content: space-between;
  margin: 12px 0;
}
.Text-module_textEditor__EFR54 .Text-module_controlPanel__agLDc > span {
  margin-left: 34px;
  margin-right: 4px;
  white-space: nowrap;
}
.Text-module_textEditor__EFR54 .DraftEditor-root {
  word-wrap: break-word;
  border: 1px solid #cad3dd;
  border-radius: 4px;
  height: 182px;
  line-height: normal;
  margin-top: 4px;
  overflow: auto;
  padding: 12px;
  scrollbar-width: thin;
}
.Text-module_textEditor__EFR54 .DraftEditor-root ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Text-module_textEditor__EFR54 .DraftEditor-root::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Text-module_textEditor__EFR54 .DraftEditor-root::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Text-module_textEditor__EFR54 .DraftEditor-root::-webkit-scrollbar-thumb:active,
.Text-module_textEditor__EFR54 .DraftEditor-root::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.App-module_app__8cgyF {
  display: grid;
  grid-template-areas: 'toolbar-top toolbar-top toolbar-top' 'toolbar-left canvas toolbar-right' 'toolbar-bottom toolbar-bottom toolbar-bottom';
  grid-template-columns: minmax(36px, max-content) 1fr minmax(36px, max-content);
  grid-template-rows: auto 1fr auto;
  height: 100%;
  position: relative;
  width: 100%;
}
.App-module_canvas__taJxn {
  grid-area: canvas;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
}
.App-module_top__SBeSV {
  -ms-grid-column-span: 3;
  background-color: #eff2f5;
  grid-area: toolbar-top;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  display: none !important;
}
.App-module_left__2iz-i {
  grid-area: toolbar-left;
  -ms-grid-column: 1;
  -ms-grid-row: 2;
}
.App-module_right__U7A5Q {
  grid-area: toolbar-right;
  -ms-grid-column: 3;
  -ms-grid-row: 2;
}
.App-module_bottom__tQA2B {
  -ms-grid-column-span: 3;
  background-color: #eff2f5;
  grid-area: toolbar-bottom;
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}
.smallEditor .App-module_app__8cgyF {
  grid-template-columns: minmax(35px, max-content) 1fr minmax(35px, max-content);
}
.Editor-module_button-common-styles__h4pal {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  min-width: 70px;
  outline: none;
}
.Editor-module_scrollbar__yVsY8 {
  scrollbar-width: thin;
}
.Editor-module_scrollbar__yVsY8 ::-webkit-scrollbar-track {
  background-color: transparent;
}
.Editor-module_scrollbar__yVsY8::-webkit-scrollbar {
  background-color: #eff2f5;
  height: 6px;
  width: 6px;
}
.Editor-module_scrollbar__yVsY8::-webkit-scrollbar-thumb {
  background-color: rgba(165, 175, 185, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.Editor-module_scrollbar__yVsY8::-webkit-scrollbar-thumb:active,
.Editor-module_scrollbar__yVsY8::-webkit-scrollbar-thumb:hover {
  background-color: #a5afb9;
}
.Editor-module_small__8cgEX body,
.Editor-module_small__8cgEX div[role='dialog'],
.Editor-module_small__8cgEX form,
.Editor-module_small__8cgEX select {
  font-size: 12px;
}
.Editor-module_editor__o0Gjz {
  background-color: #fff;
  color: #333;
  font:
    14px FreeSans,
    Arimo,
    Droid Sans,
    Helvetica,
    Helvetica Neue,
    Arial,
    sans-serif;
  height: 100%;
  position: relative;
  width: 100%;
}
.Editor-module_editor__o0Gjz select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.001 6.077-4.74-4.74.943-.942 3.797 3.797L8.797.395l.943.943-4.739 4.739Z' fill='%23231F20'/%3E%3C/svg%3E") !important;
  background-position: calc(100% - 11px) !important;
  background-repeat: no-repeat !important;
  padding: 0 8px !important;
}
.Editor-module_editor__o0Gjz li,
.Editor-module_editor__o0Gjz menu,
.Editor-module_editor__o0Gjz ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.Editor-module_editor__o0Gjz input[type='search'] {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  color: inherit;
  display: inline-block;
  font-size: 14px;
  height: 2.4em;
  line-height: 16px;
  padding: 6px 6px 6px 35px;
  vertical-align: middle;
}
.Editor-module_editor__o0Gjz input[type='search']:active,
.Editor-module_editor__o0Gjz input[type='search']:focus {
  outline: 0;
}
.Editor-module_editor__o0Gjz input[type='search']:hover {
  border: 1px solid #43b5c0;
}
.Editor-module_editor__o0Gjz input[type='search'][disabled],
.Editor-module_editor__o0Gjz input[type='search'][readonly],
fieldset[disabled] .Editor-module_editor__o0Gjz input[type='search'] {
  background: #f3f3f3;
  cursor: not-allowed;
  opacity: 0.6;
}
.Editor-module_editor__o0Gjz input[type='number'],
.Editor-module_editor__o0Gjz input[type='text'],
.Editor-module_editor__o0Gjz select,
.Editor-module_editor__o0Gjz textarea {
  background: #fff;
  border: 1px solid #cad3dd;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  font-family:
    Inter,
    FreeSans,
    Arimo,
    Droid Sans,
    Helvetica,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
  height: 24px;
  line-height: 16px;
  padding: 3px 7px;
  vertical-align: middle;
}
.Editor-module_editor__o0Gjz input[type='number']:active,
.Editor-module_editor__o0Gjz input[type='number']:focus,
.Editor-module_editor__o0Gjz input[type='text']:active,
.Editor-module_editor__o0Gjz input[type='text']:focus,
.Editor-module_editor__o0Gjz select:active,
.Editor-module_editor__o0Gjz select:focus,
.Editor-module_editor__o0Gjz textarea:active,
.Editor-module_editor__o0Gjz textarea:focus {
  caret-color: #167782;
  outline: 0;
}
.Editor-module_editor__o0Gjz input[type='number']:hover,
.Editor-module_editor__o0Gjz input[type='text']:hover,
.Editor-module_editor__o0Gjz select:hover,
.Editor-module_editor__o0Gjz textarea:hover {
  border: 1px solid #43b5c0;
}
.Editor-module_editor__o0Gjz input[type='number'][disabled],
.Editor-module_editor__o0Gjz input[type='number'][readonly],
.Editor-module_editor__o0Gjz input[type='text'][disabled],
.Editor-module_editor__o0Gjz input[type='text'][readonly],
.Editor-module_editor__o0Gjz select[disabled],
.Editor-module_editor__o0Gjz select[readonly],
.Editor-module_editor__o0Gjz textarea[disabled],
.Editor-module_editor__o0Gjz textarea[readonly],
fieldset[disabled] .Editor-module_editor__o0Gjz input[type='number'],
fieldset[disabled] .Editor-module_editor__o0Gjz input[type='text'],
fieldset[disabled] .Editor-module_editor__o0Gjz select,
fieldset[disabled] .Editor-module_editor__o0Gjz textarea {
  background-color: #e1e5ea;
  border: 1px solid #cad3dd;
  color: #585858;
  cursor: not-allowed;
}
.Editor-module_editor__o0Gjz fieldset {
  border-radius: 4px;
  margin: 0;
}
.Editor-module_editor__o0Gjz fieldset > li {
  display: inline-block;
}
.Editor-module_editor__o0Gjz fieldset.Editor-module_-hidden__aKodt {
  border-color: transparent;
  margin-top: 1.5em;
}
.Editor-module_editor__o0Gjz legend {
  color: #aeaeae;
  font-size: 12px;
  padding: 0;
  text-align: left;
}
.Editor-module_editor__o0Gjz input[type='radio'] {
  line-height: normal;
  margin: 0 0.3em;
  vertical-align: middle;
}
.Editor-module_editor__o0Gjz input[type='checkbox'] {
  line-height: normal;
  margin-right: 0.3em;
  vertical-align: middle;
}
